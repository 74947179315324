import React, { useContext } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import MyAccount from "./shop-components/my-account";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import { useEffect } from "react";
import { useState } from "react";
import axios, { API_URL } from "../lib/axios";
import { ArrayContext } from "../Added/Context";

const MyaccountV1 = () => {
  const { footerPage, setFooterPage } = useContext(ArrayContext);
  const [infos, setInfos] = useState(null);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(null);

  useEffect(() => {
    setPage(footerPage);
    axios(`/invest/frontend?code=${localStorage.getItem("lang")}`)
      .then((data) => {
        setInfos(data.data.info);
        setData(data.data.investComponent);
      })
      .catch((error) => {
        console.log("Hata:", error);
      });
    if (!page) {
      axios(`/setting/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setPage(data.data.settings);
          setFooterPage(data.data.settings);
        })
        .catch((error) => {
          console.log("Hata:", error);
        });
    }
  }, []);

  if (!infos || !data) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        {page && (
          <PageHeader
            headertitle="Yatırımcı İlişkileri"
            Img={
              API_URL + "/api/image?image=" + page.invest + "&path=investbread"
            }
          />
        )}
        <MyAccount data={data} infos={infos} />
        <CallToActionV1 />
        <Footer />
      </div>
    );
  }
};

export default MyaccountV1;
