import axios from "axios";

//export const API_URL = "http://localhost:3000"
export const API_URL = "https://webservis.safkar.com"
export const API_URL2 = "//webservis.safkar.com"

const instance = axios.create({
  baseURL: API_URL + "/api",
});

export default instance;
