import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import ContactInfo from "./section-components/contact-info";
import ContactForm from "./section-components/contact-form";
import Map from "./section-components/map";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import { useEffect } from "react";
import axios, { API_URL } from "../lib/axios";
import { useState } from "react";

const ContactV1 = () => {
  const [contact, setContact] = useState(null);
  const [form, setForm] = useState(null);

  useEffect(() => {
    axios
      .get(`/contact/frontend?code=${localStorage.getItem("lang")}`)
      .then((response) => {
        setContact(response.data.contact);
        setForm(response.data.contactForm);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  if (!form) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        {form && (
          <PageHeader
            headertitle={form.title}
            subheader={form.title}
            Img={
              API_URL + "/api/image?image=" + form.image + "&path=contactform"
            }
          />
        )}
        {contact && <ContactInfo contact={contact} />}
        {form && <ContactForm form={form} />}
        <CallToActionV1 />
        <Footer />
      </div>
    );
  }
};

export default ContactV1;
