import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import veriler from "./veriler1.json";
import axios, { API_URL } from "../../lib/axios";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const PDFPage2 = (props) => {
  const { id } = useParams();
  const [result, setResult] = useState(null);

  useEffect(() => {
    axios
      .get(API_URL + "/api/file/detail/"+id)
      .then((res) => {
        console.log(res.data);
        setResult(res.data.qr);
      });
  }, [id]);

  return (
    <>
    { result && 
<div style={{width: "100%", height: "100vh", margin:"0", overflow: "hidden"}}>
<object 
style={{width: "100%", height: "100vh"}}
data={API_URL+"/api/docs?docs=" + result + "&path=pdf"}
 type="application/pdf"></object>
</div>
    }
    </>
  );
};

export default PDFPage2;


