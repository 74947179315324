import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";

import { useState } from "react";
import { useContext } from "react";
import { ArrayContext } from "../../Added/Context";
import axios from "../../lib/axios";

const CallToActonV1 = () => {
  const { callToAction, setCallToAction } = useContext(ArrayContext);
  const [data, setData] = useState(callToAction);

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  useEffect(() => {
    if (callToAction) return;
    else {
      axios(`/footer/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setData(data.data.footer.footerlang);
          setCallToAction(data.data.footer.footerlang);
        })
        .catch((error) => {
          console.log("Hata:", error);
        });
    }
  }, []);

  if (!data)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );

  return (
    <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
              <div className="coll-to-info text-color-white">
                <h1>{data[0].title}</h1>
                <p>{data[0].content}</p>
              </div>
              <div className="btn-wrapper go-top">
                <Link className="btn btn-effect-3 btn-white" to="/iletisim">
                  {data[0].button} <i className="icon-next" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToActonV1;
