import React, { useEffect, useState } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Faq from "./section-components/faq-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import axios, { API_URL } from "../lib/axios";

const FaqV1 = () => {
  const [sss, setSss] = useState(null);
  const [faq, setFaq] = useState(null);

  useEffect(() => {
    axios
      .get(`/sss/frontend?code=${localStorage.getItem("lang")}`)
      .then((response) => {
        setSss(response.data.sss);
        setFaq(response.data.sssLang);
      });
  }, []);

  if (!sss || !faq) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        <PageHeader
          headertitle={sss.title}
          subheader={sss.title}
          Img={API_URL + "/api/image?image=" + sss.image + "&path=sss"}
        />
        <Faq faq={faq} />
        <CallToActionV1 />
        <Footer />
      </div>
    );
  }
};

export default FaqV1;
