import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class FaqV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const { faq } = this.props;

    return (
      <div className="ltn__faq-area mb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="ltn__faq-inner ltn__faq-inner-2">
                <div id="accordion_2">
                  {/* card */}
                  {faq.map((item, index) => (
                    <div className="card">
                      <h6
                        className="collapsed ltn__card-title"
                        data-bs-toggle="collapse"
                        data-bs-target={"#faq-item-2-" + item.id}
                        aria-expanded={index == 0 ? "true" : "false"}
                      >
                        {item.ssscompolentlang[0].title}
                      </h6>
                      <div
                        id={"faq-item-2-" + item.id}
                        className={index == 0 ? "collapse show" : "collapse"}
                        data-bs-parent="#accordion_2"
                      >
                        <div className="card-body">
                          <p>{item.ssscompolentlang[0].content}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* card */}
                  {/* <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-7"
                      aria-expanded="false"
                    >
                      ATP Belgesi’nin geçerlilik süresi nedir?
                    </h6>
                    <div
                      id="faq-item-2-7"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          Bu sertifika 6 yıllıktır. FRC sertifikasına göre
                          taşıma yapılacak sıcaklık aralıkları belirlenmektedir.
                          6 sene sonunda tekrar kontrol yaptırmak ve standardı
                          revize ettirmek gerekmektedir.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FaqV1;
