import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import axios from "../../lib/axios";

class Social extends Component {
  constructor(props) {
    super(props);
    this.state = {
      social: [],
    };
  }

  componentDidMount() {
    axios.get("/social/frontend").then((response) => {
      this.setState({
        social: response.data.social,
      });
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__social-media">
        <ul>
          {this.state.social.map((item, i) => (
            <li key={i}>
              <a href={item.link} title={item.name}>
                <i className={item.icon} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Social;
