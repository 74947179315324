import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import axios, { API_URL } from "../../lib/axios";
import { useEffect } from "react";
import { useState } from "react";
import WOW from "wowjs";
import { ArrayContext } from "../../Added/Context";
import { useContext } from "react";

const BannerV2 = (props) => {
  const { socialArray } = useContext(ArrayContext);
  const [social, setSocial] = useState(socialArray);
  const [data, setData] = useState(props.data);
  const [images, setImages] = useState();

  function componentDidMount() {
    const $ = window.$;
    if (data[0] && social) {
      $(".ltn__slider-11-active")
        .on(
          "init reInit afterChange",
          function (event, slick, currentSlide, nextSlide) {
            var i = (currentSlide ? currentSlide : 0) + 1;
            $(".ltn__slider-11-pagination-count .count").text("0" + i);
            $(".ltn__slider-11-pagination-count .total").text(
              "0" + slick.slideCount
            );

            $(".ltn__slider-11-slide-item-count .count").text("0" + i);
            $(".ltn__slider-11-slide-item-count .total").text(
              "/0" + slick.slideCount
            );
            new WOW.WOW().init();
          }
        )
        .slick({
          dots: false /* slider left or right side pagination count with line */,
          arrows: true /* slider arrow  */,
          // appendDots: ".ltn__slider-11-pagination-count",
          infinite: true,
          autoplay: true,
          autoplaySpeed: 7000,
          speed: 500,
          // asNavFor: ".ltn__slider-11-img-slide-arrow-active",
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow:
            '<a class="slick-prev" id="sola-yapis"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
          nextArrow:
            '<a class="slick-next" id="saga-yapis"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
          responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
              },
            },
            {
              breakpoint: 575,
              settings: {
                arrows: true,
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
    }
  }

  const findId7Or8 = () => {
    if (data[0])
      return data.map((item) =>
        item.sliderlang.map((ctx) =>
          ctx.id === 7 || ctx.id === 8 ? true : false
        )
      );
    else return false;
  };

  useEffect(() => {
    const fin = findId7Or8();

    if (fin) {
      let findedItems = [];
      fin.map((item) => findedItems.push(item[0]));
      setImages(findedItems);
    }
  }, [data]);

  useEffect(() => {
    componentDidMount();
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div
      className={`ltn__slider-area ltn__slider-11  ltn__slider-11-slide-item-count-show--- ltn__slider-11-pagination-count-show--- section-bg-1`}
    >
      <div className="ltn__slider-11-inner">
        <div className="ltn__slider-11-active">
          {/* slide-item */}
          {data[0] &&
            data.map((item, index) => (
              <div
                key={item.id}
                className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11"
              >
                {images && images[index] && (
                  <div id={"safkar-bg-100-yil"}></div>
                )}
                <div className="ltn__slide-item-inner">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 align-self-center">
                        {item.sliderlang.map((ctx) => (
                          <div key={ctx.id} className="slide-item-info">
                            <div className="slide-item-info-inner ltn__slide-animation">
                              <h6 className="slide-sub-title white-color--- animated">
                                <span>
                                  <i className={item.icon} />
                                </span>{" "}
                                {ctx.subtitle}
                              </h6>
                              <h1 className="slide-title animated ">
                                {ctx.title}
                              </h1>
                              <div className="slide-brief animated">
                                <p>{ctx.content}</p>
                              </div>
                              {ctx.link && (
                                <div className="btn-wrapper animated">
                                  <Link
                                    to={ctx.link}
                                    className="theme-btn-1 btn btn-effect-1"
                                  >
                                    Keşfet
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}

                        <div className="slide-item-img">
                          <img
                            src={
                              API_URL +
                              "/api/image?image=" +
                              item.image +
                              "&path=slider"
                            }
                            alt="#"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {/* slider-4-pagination */}
        <div className="ltn__slider-11-pagination-count">
          <span className="count" />
          <span className="total" />
        </div>
        {/* slider-sticky-icon */}
        <div className="slider-sticky-icon-2">
          <ul>
            {social?.map((item, i) => (
              <li key={i}>
                <a href={item.link} title={item.title}>
                  <i className={item.icon} />
                </a>
              </li>
            ))}
            {/* <li>
                <a href="#" title="Facebook">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#" title="Twitter">
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#" title="Linkedin">
                  <i className="fab fa-linkedin" />
                </a>
              </li> */}
          </ul>
        </div>
        {/* slider-4-img-slide-arrow */}
        <div className="ltn__slider-11-img-slide-arrow">
          <div className="ltn__slider-11-img-slide-arrow-inner">
            {/* <div className="ltn__slider-11-img-slide-arrow-active">
              {data &&
                data.map((item) => (
                  <div className="image-slide-item" key={item.id}>
                    <img
                      src={
                        API_URL +
                        "/api/image?image=" +
                        item.image +
                        "&path=slider"
                      }
                      alt="Image"
                    />
                  </div>
                ))}
            </div> */}
            <div className="ltn__slider-11-slide-item-count">
              <span className="count" />
              <span className="total" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerV2;
