import React, { useEffect, useState } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Location from "./section-components/location";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import axios, { API_URL } from "../lib/axios";

const LocationPage = () => {
  const [page, setPage] = useState(null);
  const [serviceSales, setServiceSales] = useState([]);
  const [province, setProvince] = useState([]);
  const [country, setCountry] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    axios
      .get(`/servicesales/frontend?code=${localStorage.getItem("lang")}`)
      .then((response) => {
        if (response.data.success !== false) {
          setPage(response.data.serviceSalesPage);
          setServiceSales(response.data.serviceSales);
          setCountry(response.data.country);
          setProvince(response.data.province);
          setLoader(false);
        }
      });
  }, []);

  if (loader) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        {page && (
          <PageHeader
            headertitle={page.title}
            customclass="mb-0"
            Img={
              API_URL +
              "/api/image?image=" +
              page.image +
              "&path=servicesalespage"
            }
          />
        )}
        {page && (
          <Location
            page={page}
            serviceSales={serviceSales}
            country={country}
            province={province}
          />
        )}
        <CallToActionV1 />
        <Footer />
      </div>
    );
  }
};

export default LocationPage;
