import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios, { API_URL } from "../../lib/axios";
import moment from "moment/moment";

const BlogSlider = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(props.page);

  useEffect(() => {
    // Filter out bloglang entries where active is false, then sort the data by createdAt in descending order and get the latest 3 blogs
    const filteredData = props.blog
      .filter((item) => item.bloglang.some((blg) => blg.active))
      .map((item) => ({
        ...item,
        bloglang: item.bloglang.filter((blg) => blg.active),
      }))
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    setData(filteredData.slice(0, 3));
  }, [props.blog]);

  useEffect(() => {
    const $ = window.$;

    if (data.length > 0 && page) {
      $(".ltn__blog-slider-one-active").slick({
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow:
          '<div class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></div>',
        nextArrow:
          '<div class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></div>',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: false,
              dots: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: false,
              dots: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: false,
              dots: true,
            },
          },
          {
            breakpoint: 575,
            settings: {
              arrows: false,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    }
  }, [data, page]);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  if (!page || data.length === 0) return null;

  return (
    <div className={"ltn__blog-area pt-115--- pb-70 go-top mt-70" + (props.sectionClass ? props.sectionClass : "")}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h1 className="section-title">{page.title}</h1>
            </div>
          </div>
        </div>
        <div className="row ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
          {/* Blog Item */}
          {data.map((item) => (
            <div className="col-lg-12" key={item.id}>
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <Link to={`/haber/${item.bloglang[0]?.sef}`}>
                    <img
                      style={{
                        width: "100%",
                        height: "200px", // Add the 'px' unit to the height value
                        objectFit: "contain", // This ensures the image covers the entire container
                      }}
                      src={`${API_URL}/api/image?image=${item.image}&path=blog`}
                      alt="#"
                    />
                  </Link>
                </div>
                <div
                  className="ltn__blog-brief"
                  style={{
                    height: "300px", // Full height to match parent container
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between", // Distribute space evenly
                  }}
                >
                  <div className="ltn__blog-meta">
                    <ul
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 5,
                      }}
                    >
                      <li className="ltn__blog-author">
                        <Link to={`/haber/${item.bloglang[0]?.sef}`}>
                          <i className="far fa-user" />
                          Safkar
                        </Link>
                      </li>
                      <li className="ltn__blog-tags">
                        {item.bloglang[0]?.tags.slice(0, 1).map((tag, index) => (
                          <React.Fragment key={tag}>
                            <i className="fas fa-tags" />
                            {tag}
                          </React.Fragment>
                        ))}
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to={`/haber/${item.bloglang[0]?.sef}`}>
                      {truncateText(item.bloglang[0]?.title, 100)}
                    </Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          {moment(item.bloglang[0]?.createdAt).locale(localStorage.getItem("lang")).format("MMMM Do YYYY")}
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to={`/haber/${item.bloglang[0]?.sef}`}>
                        {localStorage.getItem("lang") === "tr" ? "Devamını Gör" : "See More"}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* Blog Item */}
        </div>
      </div>
    </div>
  );
};

export default BlogSlider;
