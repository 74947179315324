import React from "react";

const TableComponent = ({ data }) => {
  // Create a temporary container element
  const tempContainer = document.createElement("div");
  tempContainer.innerHTML = data;

  // Get the first table element from the container
  const firstTable = tempContainer.querySelector("table");

  return (
    <div>
      {firstTable && (
        <div dangerouslySetInnerHTML={{ __html: firstTable.outerHTML }}></div>
      )}
    </div>
  );
};

export default TableComponent;
