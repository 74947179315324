import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Wishlist from "./section-components/wishlist";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import { useEffect } from "react";
import axios from "../lib/axios";
import { useState } from "react";

const WishlistPage = () => {
  const [kvkk, setKvkk] = useState([]);
  const [text, setText] = useState([]);

  useEffect(() => {
    Promise.all([
      axios
        .get(`/kvkk/frontend?code=${localStorage.getItem("lang")}`)
        .then((response) => {
          setKvkk(response.data.kvkk);
        }),
      axios
        .get(`/text/frontend?code=${localStorage.getItem("lang")}`)
        .then((response) => {
          setText(response.data.text);
        }),
    ]);
  }, []);

  if (!kvkk || !text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        <PageHeader headertitle={kvkk.title} />
        {kvkk.id && text.id && <Wishlist kvkk={kvkk} text={text} />}
        <CallToActionV1 />
        <Footer />
      </div>
    );
  }
};

export default WishlistPage;
