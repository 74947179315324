import React, { useEffect, useState, useRef } from "react";
import axios, { API_URL } from "../../lib/axios";
import Slider from "react-slick";

const History = (props) => {
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    const fetchHistoryData = async () => {
      try {
        const response = await axios.get(
          `/history/frontend?code=${localStorage.getItem("lang")}`
        );
        setData(response.data.history);
      } catch (error) {
        console.log("Error:", error);
      }
    };

    fetchHistoryData();
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handlePrevArrowClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextArrowClick = () => {
    sliderRef.current.slickNext();
  };

  if (!data) return null;

  const sliderSettings = {
    ref: sliderRef,
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 12,
    slidesToScroll: 1,
    prevArrow: (
      <a className="custom-arrow" onClick={handlePrevArrowClick}>
        <i className="fas fa-arrow-left" alt="Arrow Icon"></i>
      </a>
    ),
    nextArrow: (
      <a className="custom-arrow" onClick={handleNextArrowClick}>
        <i className="fas fa-arrow-right" alt="Arrow Icon"></i>
      </a>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div id="i_tarihce" className="ltn__our-history-area pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__our-history-inner">
              <div className="ltn__tab-menu text-uppercase">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <a className="custom-arrow" onClick={handlePrevArrowClick}>
                    <i className="fas fa-arrow-left" alt="Arrow Icon"></i>
                  </a>
                  <a className="custom-arrow" onClick={handleNextArrowClick}>
                    <i className="fas fa-arrow-right" alt="Arrow Icon"></i>
                  </a>
                </div>
                <Slider {...sliderSettings}>
                  {data.map((ctx, i) => (
                    <div key={ctx.id} className="ltn__tabs-item show">
                      <a
                        data-bs-toggle="tab"
                        href={`#liton_tab_2_${ctx.id}`}
                        onClick={() => handleTabClick(i)}
                        className={activeTab === i ? "active" : ""}
                      >
                        <span style={{ width: "700px" }}>{ctx.date}</span>
                      </a>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="tab-content">
                {data.map(
                  (item, index) =>
                    item.historylang &&
                    item.historylang.map((ctx) => (
                      <div
                        className={`tab-pane fade ${
                          activeTab === index ? "show active" : ""
                        }`}
                        id={`liton_tab_2_${item.id}`}
                        key={item.id}
                      >
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-6 align-self-center">
                              <div className="about-us-img-wrap about-img-left">
                                <img
                                  src={`${API_URL}/api/image?image=${item.image}&path=history`}
                                  alt="Image"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                              <div className="about-us-info-wrap">
                                <div className="section-title-area ltn__section-title-2--- text-center---">
                                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                                    SAFKAR
                                  </h6>
                                  <h1 className="section-title">{ctx.title}</h1>
                                </div>
                                <p>{ctx.content}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
