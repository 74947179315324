import React from "react";
import Navbar from "./global-components/navbar";
import ProductDetails from "./shop-components/penguen485";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "../lib/axios";

const Product_Details = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (id) {
      axios(`/product/frontend?code=${localStorage.getItem("lang")}&id=${id}`)
        .then((data) => {
          if (data.data.success !== false) {
            setProduct(data.data.product[0]);
          }
        })
        .catch((error) => {
          console.log("Hata:", error);
        });
    }
  }, [id]);

  if (!product) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        <ProductDetails product={product} />
        <CallToActionV1 />
        <Footer />
      </div>
    );
  }
};

export default Product_Details;
