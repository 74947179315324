import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import BlogDetails from "./blog-components/blog-details";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios, { API_URL } from "../lib/axios";
import { useState } from "react";

const BlogDetailsPage = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    if (id) {
      axios
        .get(`/blog/frontend?id=${id}&code=${localStorage.getItem("lang")}`)
        .then((res) => {
          setBlog(res.data.blog);
        });
    }
  }, [id]);

  if (!blog) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        {blog && (
          <PageHeader
            headertitle="Haber Detay"
            Img={API_URL + "/api/image?image=" + blog.image + "&path=blog"}
          />
        )}
        <BlogDetails blog={blog} />
        <CallToActionV1 />
        <Footer />
      </div>
    );
  }
};

export default BlogDetailsPage;
