import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Location = (props) => {
  const { state } = useLocation();
  const [selected, setSelected] = useState("satis");
  const [serviceSales, setServiceSales] = useState([]);
  const [province, setProvince] = useState([]);
  const [country, setCountry] = useState([]);
  const [page, setPage] = useState({});
  const [filteredProvince, setFilteredProvince] = useState([]);
  const [filteredServiceSales, setFilteredServiceSales] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    setSelected("satis");
    if (state && state.tab) {
      setSelected(state.tab);
    }
  }, [state]);
  let publicUrl = process.env.PUBLIC_URL + "/";

  useEffect(() => {
    if (props.page && props.serviceSales && props.country && props.province) {
      setServiceSales(props.serviceSales);
      setProvince(props.province);
      setCountry(props.country);
      setPage(props.page);
    }
  }, [props]);

  useEffect(() => {
    setFilteredProvince([]);
    if (country.length > 0 && province.length > 0) {
      const filteredprovince = province.filter(
        (item) => item.countryId == selectedCountry
      );
      setFilteredProvince(filteredprovince);
    }
  }, [selectedCountry]);

  useEffect(() => {
    setFilteredServiceSales([]);
    if (serviceSales.length > 0 && province.length > 0) {
      const filteredServiceSales = serviceSales.filter(
        (item) =>
          item.provinceId == selectedProvince &&
          item.countryId == selectedCountry
      );
      setFilteredServiceSales(filteredServiceSales);
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedCountry) {
      setFilteredProvince([]);
      setFilteredServiceSales([]);
      setSelectedProvince(null);
      setSelectedCountry(page.areadefault);
    }
  }, [selected]);

  return (
    <div>
      <div className="ltn__google-map-locations-list-area mt-115 mb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="ltn__tab-menu-list mb-50">
                <div className="nav">
                  <a
                    onClick={() => {
                      setFilteredProvince([]);
                      setFilteredServiceSales([]);
                      setSelectedCountry(page.areadefault);
                      setSelectedProvince(null);
                      setSelected("satis");
                    }}
                    className={`${selected == "satis" ? " active show " : ""}`}
                    data-bs-toggle="tab"
                    href="#ltn_tab_1_1"
                  >
                    {page.salestext} <i className={page.salesicon} />
                  </a>
                  <a
                    onClick={() => {
                      setFilteredProvince([]);
                      setFilteredServiceSales([]);
                      setSelectedCountry(page.areadefault);
                      setSelectedProvince(null);
                      setSelected("servis");
                    }}
                    className={`${selected == "servis" ? " active show " : ""}`}
                    data-bs-toggle="tab"
                    href="#ltn_tab_1_2"
                  >
                    {page.servicetext} <i className={page.serviceicon} />
                  </a>
                  {/* <a data-bs-toggle="tab" href="#ltn_tab_1_3">
											ServisNet <i className="fa-solid fa-list" />
										</a>*/}
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                <div
                  className={`${
                    selected == "satis" ? " active show " : ""
                  } tab-pane fade`}
                  id="ltn_tab_1_1"
                >
                  <div className="ltn__myaccount-tab-content-inner">
                    <div className="ltn__location-search mb-100">
                      {/* <div className="section-title-area ltn__section-title-2 ">
                        <h5 className="section-title">Satış Noktalarımız</h5>
                      </div> */}

                      <form action="#" className="clearfix">
                        <div className="row">
                          <div className="col-lg-6">
                            <h3>{page.country}</h3>
                            <div className="input-item">
                              {page.country && country && (
                                <div className="input-item select-item-subject ltn__custom-icon">
                                  <select
                                    className="custom-select"
                                    value={selectedCountry || ""}
                                    onChange={(e) => {
                                      setFilteredProvince([]);
                                      setFilteredServiceSales([]);
                                      setSelectedCountry(e.target.value);
                                    }}
                                  >
                                    <option>{page.countrydefault}</option>
                                    {country.map((item, i) => (
                                      <option key={i} value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <h3>{page.area}</h3>
                            <div className="input-item">
                              {page.areadefault && province && (
                                <div className="input-item select-item-subject ltn__custom-icon">
                                  <select
                                    className="custom-select"
                                    onChange={(e) => {
                                      setFilteredServiceSales([]);
                                      setSelectedProvince(e.target.value);
                                    }}
                                  >
                                    <option value={page.areadefault}>
                                      {page.areadefault}
                                    </option>
                                    {filteredProvince.map((item, i) => (
                                      <option key={i} value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <table className="bayi-table">
                            <thead>
                              <tr>
                                <th>{page.name}</th>
                                <th>{page.manager}</th>
                                <th>{page.email}</th>
                                <th>{page.phone}</th>
                                {/*<th>Bayi Detayı</th>*/}
                              </tr>
                            </thead>
                            <tbody>
                              {serviceSales &&
                                filteredServiceSales.map((item) => {
                                  if (item.sales) {
                                    return (
                                      <tr key={item.id}>
                                        <td>
                                          <span>{item.title}</span>
                                          <br />
                                          {item.address}
                                        </td>
                                        <td>{item.manager}</td>
                                        <td>{item.email ? item.email : "-"}</td>
                                        <td style={{
                                          whiteSpace: "nowrap",
                                          minWidth: "150px"
                                        }}>{item.phone}</td>
                                      </tr>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              {/* <tr>
                                <td className="bayi-adres">
                                  <span>YILDIZ OTO</span>
                                  <br />
                                  419/4 SK.NO:21 5. SAN.SİT. PINARBAŞI / İZMİR
                                </td>
                                <td>Yücel Yavuz</td>
                                <td>-</td>
                                <td>
                                  0-232-479 74 54
                                  <br />
                                  0-232-479 67 68
                                </td>
                                <td>
																	<button className="bayi-detay">Detay</button>
																</td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div
                  className={`${
                    selected == "servis" ? " active show " : ""
                  } tab-pane fade`}
                  id="ltn_tab_1_2"
                >
                  <div className="ltn__myaccount-tab-content-inner">
                    <div className="ltn__location-search mb-100">
                      {/*
									<div className="section-title-area ltn__section-title-2 ">
										<h5 className="section-title">
										Satış Noktalarımız
										</h5>
									</div>
									*/}
                      {/* <span>
                        Yurtiçi ve yurtdışı en yakın bayi ve servis
                        noktalarımıza ulaşmak için lütfen bizimle irtibata
                        geçiniz.
                      </span> */}
                      <form action="#" className="clearfix">
                        <div className="row">
                          <div className="col-lg-6">
                            <h3>{page.country}</h3>
                            <div className="input-item">
                              {page.country && country && (
                                <div className="input-item select-item-subject ltn__custom-icon">
                                  <select
                                    className="custom-select"
                                    value={selectedCountry || ""}
                                    onChange={(e) => {
                                      setFilteredProvince([]);
                                      setFilteredServiceSales([]);
                                      setSelectedCountry(e.target.value);
                                    }}
                                  >
                                    <option>{page.countrydefault}</option>
                                    {country.map((item, i) => (
                                      <option key={i} value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <h3>{page.area}</h3>
                            <div className="input-item ltn__custom-icon">
                              {page.areadefault && province && (
                                <div className="input-item select-item-subject ltn__custom-icon">
                                  <select
                                    className="custom-select"
                                    onChange={(e) => {
                                      setFilteredServiceSales([]);
                                      setSelectedProvince(e.target.value);
                                    }}
                                  >
                                    <option value={page.areadefault}>
                                      {page.areadefault}
                                    </option>
                                    {filteredProvince.map((item, i) => (
                                      <option key={i} value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <table className="bayi-table">
                            <thead>
                              <tr>
                                <th>{page.name}</th>
                                <th>{page.manager}</th>
                                <th>{page.email}</th>
                                <th>{page.phone}</th>
                                {/*<th>Bayi Detayı</th>*/}
                              </tr>
                            </thead>
                            <tbody>
                              {serviceSales &&
                                filteredServiceSales.map((item) => {
                                  if (item.service) {
                                    return (
                                      <tr key={item.id}>
                                        <td>
                                          <span>{item.title}</span>
                                          <br />
                                          {item.address}
                                        </td>
                                        <td>{item.manager}</td>
                                        <td>{item.email ? item.email : "-"}</td>
                                        <td>{item.phone}</td>
                                      </tr>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              {/* <tr>
                                <td>
                                  <span>YILDIZ OTO</span>
                                  <br />
                                  419/4 SK.NO:21 5. SAN.SİT. PINARBAŞI / İZMİR
                                </td>
                                <td>Yücel Yavuz</td>
                                <td>-</td>
                                <td>
                                  0-232-479 74 54
                                  <br />
                                  0-232-479 67 68
                                </td>
                                <td>
																	<button className="bayi-detay">Detay</button>
																</td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/*
									<div className="tab-pane fade" id="ltn_tab_1_3">
										<div className="ltn__myaccount-tab-content-inner">
											<div className="ltn__location-search mb-100">
												
											<div className="section-title-area ltn__section-title-2 ">
												<h5 className="section-title">
												Satış Noktalarımız
												</h5>
											</div>
											
												<form action="#" className="clearfix">
													<div className="row">
														<div className="col-lg-6">
															<h3>Kullanıcı Adı</h3>
															<div className="input-item">
																<input
																	type="text"
																	name="name"
																	placeholder="Kullanıcı Adı"
																/>
															</div>
														</div>
														<div className="col-lg-6">
															<h3>Şifre</h3>
															<div className="input-item">
																<input
																	type="password"
																	name="password"
																	placeholder="Şifre"
																/>
															</div>
														</div>
														<div className="col-lg-12">
															<div className="input-item">
																<button
																	className="btn theme-btn-1 btn-effect-1"
																	type="submit"
																>
																	Giriş Yap
																</button>
																<div className="go-to-btn mt-20">
																	<a
																		href="#"
																		title="Forgot Password?"
																		data-bs-toggle="modal"
																		data-bs-target="#ltn_forget_password_modal"
																	>
																		<small>Şifremi unuttum</small>
																	</a>
																</div>
															</div>
														</div>
													</div>
													<div className="ltn__modal-area ltn__add-to-cart-modal-area----">
														<div
															className="modal fade"
															id="ltn_forget_password_modal"
															tabIndex={-1}
														>
															<div
																className="modal-dialog modal-md"
																role="document"
															>
																<div className="modal-content">
																	<div className="modal-header">
																		<button
																			type="button"
																			className="close"
																			data-bs-dismiss="modal"
																			aria-label="Close"
																		>
																			<span aria-hidden="true">×</span>
																		</button>
																	</div>
																	<div className="modal-body">
																		<div className="ltn__quick-view-modal-inner">
																			<div className="modal-product-item">
																				<div className="row">
																					<div className="col-12">
																						<div className="modal-product-info text-center">
																							<h4>Şifrenizi mi unuttunuz?</h4>
																							<p className="added-cart">
																								{" "}
																								E-mail adresinizi girerek bize
																								bir bildirim gönderebilirsiniz.
																							</p>
																							<form
																								action="#"
																								className="ltn__form-box"
																							>
																								<input
																									type="text"
																									name="email"
																									placeholder="Email adresinizi giriniz*"
																								/>
																								<div className="btn-wrapper mt-0">
																									<button
																										className="theme-btn-1 btn btn-full-width-2"
																										type="submit"
																									>
																										Gönder
																									</button>
																								</div>
																							</form>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
