import React from "react";
import { Link } from "react-router-dom";
import axios from "../../lib/axios";
import { useEffect, useState } from "react";

const CopyRight = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const [sss, setSss] = useState(null);
  const [kvkk, setKvkk] = useState(null);
  const [text, setText] = useState(null);

  useEffect(() => {
    axios
      .get(`/sss/frontend?code=${localStorage.getItem("lang")}`)
      .then((response) => {
        setSss(response.data.sss);
      });
    axios
      .get(`/kvkk/frontend?code=${localStorage.getItem("lang")}`)
      .then((response) => {
        setKvkk(response.data.kvkk);
      });
    axios
      .get(`/text/frontend?code=${localStorage.getItem("lang")}`)
      .then((response) => {
        setText(response.data.text);
      });
  }, []);

  return (
    <div className="ltn__copyright-area ltn__copyright-2 section-bg-7 plr--5">
      <div className="container-fluid ltn__border-top-2">
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="ltn__copyright-design clearfix">
              <p>
              {localStorage.getItem("lang") == "tr" ? "Tüm Hakları Saklıdır © SAFKAR" : "All Rights Reserved © SAFKAR"}
              <span className="current-year" /> 
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12 align-self-center">
            <div className="ltn__copyright-menu text-end">
              <ul className="go-top">
                <li>
                  <a
                    href="https://e-sirket.mkk.com.tr/esir/Dashboard.jsp#/sirketbilgileri/24369"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {localStorage.getItem("lang") == "tr" ? "Bilgi Toplumu Hizmetleri" : "Information Society Services"}
                  </a>
                </li>
                {text && text.title && (                  
                  <li>
                    <Link
                      onClick={() =>
                        window.scrollTo({ top: 800, behavior: "auto" })
                      }
                      to={
                        localStorage.getItem("lang") == "tr" ? "/gizlilik" : "/privacy"
                      }
                    >
                      {text.subtitle}
                    </Link>
                  </li>
                )}
                {kvkk && kvkk.title && (
                  <li>
                    <Link
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "auto" })
                      }
                      to={
                        localStorage.getItem("lang") == "tr" ? "/gizlilik" : "/privacy"
                      }
                    >
                      {kvkk.subtitle}
                    </Link>
                  </li>
                )}
                {sss && sss.title && (
                  <li>
                    <Link
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "auto" })
                      }
                      to="/faq"
                    >
                      {sss.title}
                    </Link>
                  </li>
                )}
                
                  
                {/* <li>
                  <Link
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "auto" })
                    }
                    to="/faq"
                  >
                    {sss.title}
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyRight;
