import React from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../lib/axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";

const BlogGrid = (props) => {
  const [blog, setBlog] = useState(props.blog);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationLimit, setPaginationLimit] = useState(9);
  const [filteredPage, setFilteredPage] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  function componentDidMount() {
    if (blog) {
      console.log(blog);
      const sortedData = blog.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setBlog(sortedData);
      setFilteredPage(blog.slice(0 * paginationPage, paginationLimit));
      setTotalPage(Math.ceil(blog.length / paginationLimit));
    }
  }

  useEffect(() => {
    componentDidMount();
  }, [blog]);

  function handlePageChange(pageNumber) {
    setPaginationPage(pageNumber);
    setFilteredPage(
      blog.slice(
        (pageNumber - 1) * paginationLimit,
        pageNumber * paginationLimit
      )
    );
  }

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  useEffect(() => {
    handlePageChange(paginationPage);
  }, [paginationPage]);

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  return (
    <div className="ltn__blog-area ltn__blog-item-3-normal mb-100 go-top">
      <div className="container">
        <div className="row">
          {/* Blog Item */}
          {filteredPage.map((item) => (
            item.bloglang[0]?.active &&
            <div key={item.id} className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <Link to={ localStorage.getItem("lang") === "tr" ? `/haber/${item.bloglang[0]?.sef}` : `/news-detail/${item.bloglang[0]?.sef}`}>
                    <img
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "contain",
                      }}
                      src={
                        API_URL +
                        "/api/image?image=" +
                        item.image +
                        "&path=blog"
                      }
                      alt="#"
                    />
                  </Link>
                </div>
                <div
                  className="ltn__blog-brief"
                  style={{
                    height: "300px", // Full height to match parent container
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between", // Distribute space evenly
                  }}
                >
                  <div className="ltn__blog-meta">
                    <ul
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 5,
                      }}
                    >
                      <li className="ltn__blog-author">
                        <Link to={localStorage.getItem("lang") === "tr" ? "/haberler" : "/news"}>
                          <i className="far fa-user" />
                          Safkar
                        </Link>
                      </li>
                      {item?.bloglang && (
                        <li className="ltn__blog-tags">
                          {item.bloglang[0]?.tags.map((tag, index) => {
                            if (index > 0) {
                              return;
                            }
                            return (
                              <div key={index}>
                                <i className="fas fa-tags" />
                                {tag}
                              </div>
                            );
                          })}
                        </li>
                      )}
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to={ localStorage.getItem("lang") === "tr" ? `/haber/${item.bloglang[0]?.sef}` : `/news-detail/${item.bloglang[0]?.sef}`}>
                      {truncateText(item?.bloglang && item.bloglang[0]?.title,100)}
                    </Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          {moment(item.createdAt)
                            .locale(localStorage.getItem("lang"))
                            .format("MMMM Do YYYY")}
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to={ localStorage.getItem("lang") === "tr" ? `/haber/${item.bloglang[0]?.sef}` : `/news-detail/${item.bloglang[0]?.sef}`}>
                        {localStorage.getItem("lang") === "tr" ? "Devamını Gör" : "See More"}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* Blog Item */}
          {/* <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <Link to="/haber-detay">
                    <img
                      src={publicUrl + "assets/img/blog/ml810g.jpeg"}
                      alt="#"
                    />
                  </Link>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <Link to="/haberler">
                          <i className="far fa-user" />
                          Safkar
                        </Link>
                      </li>
                      <li className="ltn__blog-tags">
                        <Link to="/haberler">
                          <i className="fas fa-tags" />
                          Haberler
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to="/haber-detay">
                      MIL 810G/F standartlarındaki cihazlarımız
                    </Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          30 Temmuz 2021
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to="/haber-detay">Devamını Gör</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          {/*  */}
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-sm-6 col-12">
            <div className="ltn__pagination-area text-center">
              <div className="ltn__pagination">
                <ul>
                  <li
                    onClick={(e) => {
                      if (paginationPage > 1) {
                        setPaginationPage(paginationPage - 1);
                      }
                    }}
                  >
                    <Link to="#">
                      <i className="fas fa-angle-double-left" />
                    </Link>
                  </li>
                  {Array.from(Array(totalPage).keys()).map((item) => (
                    <li
                      key={item}
                      onClick={(e) => setPaginationPage(item + 1)}
                      className={paginationPage === item + 1 ? "active" : ""}
                    >
                      <Link to="#">{item + 1}</Link>
                    </li>
                  ))}
                  {/* <li>
                      <Link to="#">2</Link>
                    </li> */}
                  <li
                    onClick={(e) => {
                      if (paginationPage < totalPage) {
                        setPaginationPage(paginationPage + 1);
                      }
                    }}
                  >
                    <Link to="#">
                      <i className="fas fa-angle-double-right" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogGrid;
