import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import BlogGrid from "./blog-components/blog-grid";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import axios, { API_URL } from "../lib/axios";
import { useState } from "react";

const BlogGridPage = () => {
  const [page, setPage] = useState(null);
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    Promise.all([
      axios
        .get(`/news/frontend?code=${localStorage.getItem("lang")}`)
        .then((response) => {
          setPage(response.data.news);
        }),
      axios
        .get(`/blog/frontend?code=${localStorage.getItem("lang")}`)
        .then((response) => {
          setBlog(response.data.blog);
        }),
    ]);
  }, []);

  if (!page || !blog) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        {page && (
          <PageHeader
            headertitle={page.title}
            Img={API_URL + "/api/image?image=" + page.image + "&path=news"}
          />
        )}
        {blog[0] && <BlogGrid blog={blog} />}
        <CallToActionV1 />
        <Footer />
      </div>
    );
  }
};

export default BlogGridPage;
