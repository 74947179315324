import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";

import axios from "../../lib/axios";
import { API_URL } from "../../lib/axios";

const ShopDetails = (props) => {
  const { product } = props;

  let publicUrl = API_URL + "/";

  return (
    <div className="ltn__shop-details-area pt-100 pb-10">
      {product && (
        <div className="container">
          <div className="row">
            <div className="col-lg-12 product__properties">
              <h1></h1>
              <h4 className="title-2 mb-2">{product.productlang[0].title}</h4>
              {product.productlang[0].content == "" ? null : (
                <div>
                  <br />
                  {product.productlang[0].content}
                </div>
              )}
            </div>
            {product.sideimage && (
              <div className="col-lg-4">
                <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                  {/* Menu Widget (Category) */}
                  <div
                    className="widget ltn__menu-widget ltn__menu-widget-2--- ltn__menu-widget-2-color-2---"
                    style={{ padding: "20px" }}
                  >
                    <img
                      src={
                        API_URL +
                        "/api/image?image=" +
                        product.sideimage +
                        "&path=product/sideImage"
                      }
                      alt="Image"
                    />
                  </div>

                  {/* Popular Product Widget */}

                  <div className="row">
                    <div className={product?.showFile && product?.category?.file ? "col-lg-6" : "col-lg-12"}>
                      {/* pdf button */}
                      {product.file && (
                        <a
                          href={
                            API_URL +
                            "/api/docs?docs=" +
                            product.file +
                            "&path=product/pdf"
                          }
                          target="_blank"
                          className="btn btn-primary"
                          style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px", marginTop: "10px" }}
                        >
                          <i className="fas fa-file-pdf"></i> PDF
                        </a>
                      )}
                    </div>
                    {product?.showFile && product?.category.file && (
                      <div className= {product.file ? "col-lg-6" : "col-lg-12" }>
                        {/* pdf button */}

                        <a
                          href={
                            API_URL +
                            "/api/docs?docs=" +
                            product.category.file +
                            "&path=category/pdf"
                          }
                          target="_blank"
                          className="btn btn-primary"
                          style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px", marginTop: "10px" }}
                        >
                          <i className="fas fa-file-pdf"></i> E-Katalog
                        </a>
                      </div>
                    )}
                  </div>

                  {/* Banner Widget */}
                  {/* <div className="widget ltn__banner-widget d-none go-top">
                    <Link to="/urunler">
                      <img
                        src={publicUrl + "assets/img/banner/2.jpg"}
                        alt="#"
                      />
                    </Link>
                  </div> */}
                </aside>
              </div>
            )}
            <div
              className={
                product.sideimage
                  ? "col-lg-8 col-md-12"
                  : "col-lg-12s col-md-12"
              }
            >
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <div className="ltn__blog-meta">
                  <ul>
                    <li className="ltn__blog-category">
                      <Link to="/urunler">
                        {product.category.categorylang[0].title}
                      </Link>
                    </li>
                    {/* <li>
                      <img
                        src={publicUrl + "assets/img/product/penguen.png"}
                        style={{ maxWidth: "100px" }}
                        alt="Image"
                      />
                    </li> */}
                  </ul>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: product.productlang[0].detail,
                  }}
                ></div>
              </div>
            </div>
            <div className="col-lg-12">
              {product.bottomimage && (
                <img
                  src={
                    API_URL +
                    "/api/image?image=" +
                    product.bottomimage +
                    "&path=product/bottomImage"
                  }
                  alt="Image"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopDetails;
