import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ContactInfo extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let { contact } = this.props;

    return (
      <div className="ltn__contact-address-area mb-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="ltn__map-item">
                <h3 className="ltn__location-name">{contact.name}</h3>
                <h5 className="ltn__location-single-info">{contact.address}</h5>
                <h4 className="ltn__location-single-info">
                  <i className="fas fa-phone-volume" />
                  <a href={"tel:+" + contact.phone1}>{contact.phone1}</a>{" "}
                </h4>
                <h4 className="ltn__location-single-info">
                  <i className="fas fa-phone-volume" />
                  <a href={"tel:+" + contact.phone2}>{contact.phone2}</a>{" "}
                </h4>
                <h4 className="ltn__location-single-info">
                  <i className="fas fa-envelope" />
                  {contact.email1}
                </h4>
                <h4 className="ltn__location-single-info">
                  <i className="fas fa-envelope" />
                  {contact.email2}
                </h4>
                <div className="btn-wrapper justify-content-center">
                  <a
                    href={contact.map}
                    target={"_blank"}
                    className="btn btn-transparent btn-border btn-effect-3"
                  >
                    <i className="fas fa-globe" />
                  {
                    localStorage.getItem("lang") === "en" ? "View on Map" : "Haritada Görüntüle"
                  }
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24969.311505146503!2d27.029686!3d38.529989!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd1057ab35371%3A0x4a12aebac4db433e!2sSafkar!5e0!3m2!1str!2str!4v1719632158017!5m2!1str!2str"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInfo;

