import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import veriler from "./veriler1.json";
import axios, { API_URL } from "../../lib/axios";
import { useState } from "react";
import { useEffect } from "react";

const MyAccount = (props) => {
  const [data, setData] = useState(props.data || null);
  const [infos, setInfos] = useState(props.infos || null);

  if (infos.length === 0) return null;

  let publicUrl = process.env.PUBLIC_URL + "/";
  let ImgKap = "assets/img/icons/kap-logo.png";
  let ImgPdf = "assets/img/icons/icon-pdf.png";
  // function tarihFormatla(tarih) {
  //   const [gun, ay, yil] = tarih.split(".");
  //   return new Date(`${yil}-${ay}-${gun}`);
  // }
  // function filtreleYillaraGore(veriler, yil) {
  //   return veriler.filter((veri) => {
  //     const veriYili = tarihFormatla(veri.tarih).getFullYear();
  //     return veriYili === yil;
  //   });
  // }
  // const yil2023Verileri = filtreleYillaraGore(veriler, 2023);
  // const yil2022Verileri = filtreleYillaraGore(veriler, 2022);
  // const yil2021Verileri = filtreleYillaraGore(veriler, 2021);
  // const yil2020Verileri = filtreleYillaraGore(veriler, 2020);
  // const yil2019Verileri = filtreleYillaraGore(veriler, 2019);
  // const yil2018Verileri = filtreleYillaraGore(veriler, 2018);

  function tarihFormatla(tarih) {
    // set the date 3 hours behind UTC
    // var [yil, ay, gun] = tarih.split("T")[0].split("-");

    const date = new Date(tarih);
    date.setUTCHours(date.getUTCHours()); // Set the hours 3 hours behind UTC

    // get date to year
    const yil = date.getFullYear();
    const ay = date.getMonth() + 1;
    const gun = date.getDate();

    return new Date(`${yil}-${ay}-${gun}`);
  }

  function filtreleYillaraGore(veriler, yil) {
    return veriler.filter((veri) => {
      const veriYili = tarihFormatla(veri.date).getFullYear();
      return veriYili === yil;
    });
  }

  let yillar = [];
  let sonuc = [];

  return (
    <div className="liton__wishlist-area pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__product-tab-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="ltn__tab-menu-list mb-50">
                      <div className="nav">
                        <a
                          className="active show"
                          data-bs-toggle="tab"
                          href="#ltn_tab_1_1"
                        >
                          Şirket Bilgileri <i className="fas  fa-circle-info" />
                        </a>
                        {data.map((item, i) => (
                          <a data-bs-toggle="tab" href={`#ltn_tab_1_${i + 2}`}>
                            {item.name} <i className={item.icon} />
                          </a>
                        ))}

                        {/* <a data-bs-toggle="tab" href="#ltn_tab_1_2">
                            Yönetim Kurulu <i className="fa-solid fa-list" />
                          </a>
                         */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show"
                        id="ltn_tab_1_1"
                      >
                        <div className="ltn__myaccount-tab-content-inner">
                          <p>
                            {infos.map((info) => (
                              <>
                                <h6>{info.name}</h6>
                                {info.value}
                              </>
                            ))}
                            {/* <h6>Ünvanı</h6>
                              SAFKAR EGE SOĞUTMACILIK KLİMA SOĞUK HAVA TESİSLERİ
                              İHRACAT İTHALAT SANAYİ VE TİCARET ANONİM ŞİRKETİ
                              <h6>Kuruluş</h6>
                              1988
                              <h6>Tescilli Adres</h6>
                              ULUKENT SANAYİ BÖLGESİ 1. ASKERİ HAVA LOJMANLARI
                              KARŞISI ULUKENT MENEMEN-İZMİR
                              <h6>Merkez</h6>
                              İZMİR
                              <h6>Ticaret Sicil No</h6>
                              2148
                              <h6>Sanayi Sicil No</h6>
                              12313
                              <h6>Vergi Dairesi ve No</h6>
                              MENEMEN - 3250055640
                              <h6>Mersis No</h6>
                              0325005564000011
                              <h6>Taahhüt Ed. Sermaye</h6>
                              35.250.000
                              <h6>Ödenmiş Sermaye</h6>
                              35.250.000-TL
                              <h6>Denetçi</h6>
                              ÇÖZÜM ÜNLÜER BAĞIMSIZ DENETİM VE YEMİNLİ MALİ
                              MÜŞAVİRLİK A.Ş. */}
                          </p>
                        </div>
                      </div>
                      {data.map((item, i) => {
                        let yillar, sonuc;

                        switch (item.type) {
                          case "LINK":
                            yillar = Array.from(
                              new Set(
                                item.links.links.map((veri) =>
                                  tarihFormatla(veri.date).getFullYear()
                                )
                              )
                            );

                            sonuc = {};

                            yillar.forEach((yil) => {
                              sonuc[yil] = filtreleYillaraGore(
                                item.links.links,
                                yil
                              );
                            });
                            switch (item.short) {
                              case true:
                                return (
                                  <div
                                    className="tab-pane fade"
                                    id={`ltn_tab_1_${i + 2}`}
                                    key={`ltn_tab_1_${i + 2}`}
                                  >
                                    <div className="ltn__myaccount-tab-content-inner">
                                      <div className="ltn__faq-inner ltn__faq-inner-2">
                                        <div
                                          id={`accordion_${i}`}
                                          className="kap-link"
                                        >
                                          {yillar.map((yil, index) => (
                                            <div
                                              className="card"
                                              key={`faq-item-2-${index}`}
                                            >
                                              <h6
                                                className="ltn__card-title"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#faq-item-2-${index}`}
                                                aria-expanded={index === 0}
                                              >
                                                {yil} Yılı {item.name}
                                              </h6>
                                              <div
                                                id={`faq-item-2-${index}`}
                                                className={
                                                  index === 0
                                                    ? "collapse show"
                                                    : "collapse"
                                                }
                                                data-bs-parent={`#accordion_${i}`}
                                              >
                                                <div className="card-body">
                                                  <div className="pdf-inner">
                                                    {sonuc[yil].map(
                                                      (veri, index) => (
                                                        <a
                                                          key={`veri_${index}`}
                                                          href={veri.link}
                                                          target="_blank"
                                                        >
                                                          <img
                                                            className="kap-img"
                                                            src={
                                                              publicUrl + ImgKap
                                                            }
                                                            alt="Image"
                                                          />
                                                          {item.dateshow && (
                                                            <span
                                                              className="kap-date"
                                                              style={{
                                                                marginLeft:
                                                                  "10px",
                                                              }}
                                                            >
                                                              {tarihFormatla(
                                                                veri.date
                                                              ).toLocaleDateString(
                                                                "tr-TR"
                                                              )}
                                                              {" - "}
                                                            </span>
                                                          )}
                                                          <span className="kap-title">
                                                            {veri.name}
                                                          </span>
                                                        </a>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );

                              default:
                                return (
                                  <div
                                    className="tab-pane fade"
                                    id={`ltn_tab_1_${i + 2}`}
                                  >
                                    <div className="ltn__myaccount-tab-content-inner">
                                      <div className="pdf-inner">
                                        {item.links.links.map((item) => (
                                          <>
                                            <a
                                              href={item.link}
                                              target={"_blank"}
                                            >
                                              <img
                                                className="kap-img"
                                                src={publicUrl + ImgKap}
                                                alt="Image"
                                              />{" "}
                                              {item.name}
                                            </a>
                                          </>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                );
                            }

                          case "PDF":
                            yillar = Array.from(
                              new Set(
                                item.files.pdf.map((veri) =>
                                  tarihFormatla(veri.date).getFullYear()
                                )
                              )
                            );

                            sonuc = {};

                            yillar.forEach((yil) => {
                              sonuc[yil] = filtreleYillaraGore(
                                item.files.pdf,
                                yil
                              );
                            });
                            switch (item.short) {
                              case true:
                                return (
                                  <div
                                    className="tab-pane fade"
                                    id={`ltn_tab_1_${i + 2}`}
                                    key={`ltn_tab_1_${i + 2}`}
                                  >
                                    <div className="ltn__myaccount-tab-content-inner">
                                      <div className="ltn__faq-inner ltn__faq-inner-2">
                                        <div
                                          id={`accordion_${i}`}
                                          className="kap-pdfs"
                                        >
                                          {yillar.map((yil, index) => (
                                            <div
                                              className="card"
                                              key={`faq-item-2-${index}`}
                                            >
                                              <h6
                                                className="ltn__card-title"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#faq-item-2-${index}`}
                                                aria-expanded={index === 0}
                                              >
                                                {yil} Yılı {item.name}
                                              </h6>
                                              <div
                                                id={`faq-item-2-${index}`}
                                                className={
                                                  index === 0
                                                    ? "collapse show"
                                                    : "collapse"
                                                }
                                                data-bs-parent={`#accordion_${i}`}
                                              >
                                                <div className="card-body">
                                                  <div className="pdf-inner">
                                                    {sonuc[yil].map(
                                                      (veri, index) => (
                                                        <a
                                                          key={`veri_${index}`}
                                                          href={localStorage.getItem("lang") === "tr" ? `/belge/${veri.pdf}` : `/document/${veri.pdf}`}
                                                          target="_blank"
                                                        >
                                                          <img
                                                            src={
                                                              publicUrl + ImgPdf
                                                            }
                                                            alt="Image"
                                                          />
                                                          <span
                                                            style={{
                                                              marginLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            {item.dateshow && (
                                                              <>
                                                                {
                                                                  veri.date.split(
                                                                    "T"
                                                                  )[0]
                                                                }{" "}
                                                                - {veri.name}
                                                              </>
                                                            )}
                                                            {!item.dateshow &&
                                                              veri.name}
                                                          </span>
                                                        </a>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              default:
                                return (
                                  <div
                                    className="tab-pane fade"
                                    id={`ltn_tab_1_${i + 2}`}
                                  >
                                    <div className="ltn__myaccount-tab-content-inner">
                                      <div className="pdf-inner">
                                        {item.files.pdf.map((pdf) => {
                                          var pdfFile = pdf.pdf;
                                          return (
                                          <>
                                            <a
                                              href={
                                                localStorage.getItem("lang") === "tr" ? "/belge/" + pdfFile : "/document/" + pdfFile
                                              }
                                              target={"_blank"}
                                            >
                                              <img
                                                src={publicUrl + ImgPdf}
                                                alt="Image"
                                              />

                                              {item.dateshow === true ? (
                                                <>
                                                  {pdf.date.split("T")[0]} -{" "}
                                                  {pdf.name}
                                                </>
                                              ) : (
                                                <>{pdf.name}</>
                                              )}
                                            </a>
                                          </>
                                        )
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                            }

                          case "IMAGE":
                            return (
                              <div
                                className="tab-pane fade"
                                id={`ltn_tab_1_${i + 2}`}
                                key={`ltn_tab_1_${i + 2}`}
                              >
                                <div className="ltn__myaccount-tab-content-inner">
                                  <img
                                    src={`${API_URL}/uploads/invest/${item.image}`}
                                    alt="Image"
                                  />
                                </div>
                              </div>
                            );

                          case "TEXT":
                            return (
                              <div
                                className="tab-pane fade"
                                id={`ltn_tab_1_${i + 2}`}
                                key={`ltn_tab_1_${i + 2}`}
                              >
                                <div className="ltn__myaccount-tab-content-inner">
                                  <div className="pdf-inner">
                                    {parse(item.text)}
                                  </div>
                                </div>
                              </div>
                            );

                          default:
                            return (
                              <div
                                className="tab-pane fade"
                                id={`ltn_tab_1_${i + 2}`}
                                key={`ltn_tab_1_${i + 2}`}
                              >
                                <div className="ltn__myaccount-tab-content-inner">
                                  <div className="pdf-inner">
                                    {/* You can add your fallback content here */}
                                  </div>
                                </div>
                              </div>
                            );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;


