import React, { useEffect, useState } from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner-v2";
import Aboutv3 from "./section-components/about-v3";
import ServiceV1 from "./section-components/service-v1";
import Video from "./section-components/video-v2";
import UpComingProduct from "./section-components/upcoming-product-v1";
import ProductSlider from "./section-components/product-slider-v2";
import BlogSlider from "./blog-components/blog-slider-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import PopUp from "./global-components/popup";
import moment from "moment";
import axios from "../lib/axios";
import { ArrayContext } from "../Added/Context";
import { useContext } from "react";

const Home_V2 = () => {
  const [loader, setLoader] = React.useState(true);
  const [slider, setSlider] = useState([]);
  const [whyUsPage, setWhyUsPage] = useState([]);
  const [whyUsData, setWhyUsData] = useState([]);
  const [product, setProduct] = useState([]);
  const [topProductComponent, setTopProductComponent] = useState([]);
  const [topProductComponentLang, setTopProductComponentLang] = useState([]);
  const [blog, setBlog] = useState([]);
  const [blogPage, setBlogPage] = useState([]);

  const {
    setNavbarArray,
    setCategoriesArray,
    setSocialArray,
    setFooterPage,
    setFooterSettings,
    setFooterData,
    setCallToAction,
  } = useContext(ArrayContext);

  const componentDidMount = () => {
    Promise.all([
      axios("/language/frontend")
        .then((data) => {
          setNavbarArray(data.data.language);
          if (localStorage.getItem("lang") === null) {
            localStorage.setItem("lang", "tr");
          } else {
            return null;
          }
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
      axios(`social/frontend`).then((response) => {
        setSocialArray(response.data.social);
      }),
      axios(`/category/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setCategoriesArray(data.data.category);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
      axios(`/slider/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setSlider(data.data.slider);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),

      axios(`/setting/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setFooterPage(data.data.settings);
          setFooterSettings(data.data.settingContext);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
      axios
        .get(`/footer/frontend?code=${localStorage.getItem("lang")}`)
        .then((response) => {
          if (response.data.footer) {
            setFooterData(response.data.footer);
          }
        }),
      axios(`/whyus/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setWhyUsPage(data.data.whyus);
          setWhyUsData(data.data.whyusLang);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
      axios(`/product/frontend?code=${localStorage.getItem("lang")}&top=1`)
        .then((data) => {
          setProduct(data.data.product);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
      axios(
        `/topproductcompolent/frontend?code=${localStorage.getItem("lang")}`
      )
        .then((data) => {
          setTopProductComponent(data.data.topProduct[0]);
          setTopProductComponentLang(data.data.topProductLang[0]);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
      axios(`/footer/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setCallToAction(data.data.footer.footerlang);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
      axios(`/blog/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setBlog(data.data.blog);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
      axios(`/news/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setBlogPage(data.data.news);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
    ])
      .finally(() => {
        moment.locale("tr");
        setLoader(false);
      })
      .catch((error) => {
        console.log("Hata:", error);
      });
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  if (!loader) {
    return (
      <div>
        <PopUp />
        <Navbar />
        <Banner data={slider} />
        {/* <Aboutv3 />*/}
        <ServiceV1 title={topProductComponentLang.pageTitle} />
        <Video data={whyUsData} page={whyUsPage} />
        <UpComingProduct
          topProduct={topProductComponent}
          topProductLang={topProductComponentLang}
          product={product}
        />
        {/*<ProductSlider />*/}
        <BlogSlider blog={blog} page={blogPage} />
        <CallToActionV1 />
        <Footer />
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
};

export default Home_V2;
