import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Sidebar from './shop-sidebar';

class ShopGridV1 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div>
			<div className="ltn__product-area ltn__product-gutter">
				<div className="container">
					<div className="row">
						<div className="col-8  mb-100">
							<div className="ltn__shop-options">
								<ul className="justify-content-start">
									<li>
										<div className="ltn__grid-list-tab-menu ">
											<div className="nav">
												<a className="active show" data-bs-toggle="tab" href="#liton_product_grid"><i className="fas fa-th-large" /></a>
												
											</div>
										</div>
									</li>
									{/*
									<li className="d-none">
										<div className="showing-product-number text-right">
											<span>Seçiminize göre 7 ürün gösteriliyor.</span>
										</div>
									</li>
									<li>
										<div className="short-by text-center">
											<select className="nice-select">
												<option>Tüm Kategoriler</option>
												<option>Ticari Araç Klimaları</option>
												<option>Frigorifik Soğutucular</option>
												<option>Raylı Sistemler</option>
												<option>Savunma Sanayi</option>
												<option>E-Series</option>
											</select>
										</div>
									</li>
									<li>
										<div className="short-by text-center">
											<select className="nice-select">
												<option>Tüm Alt Kategoriler</option>
												<option>Minibüs Klimaları</option>
												<option>Midibüs Klimaları</option>
												<option>Otobüs Klimaları</option>
											</select>
										</div>
									</li>
									*/}
								</ul>
							</div>
							<div className="tab-content">
								<div className="tab-pane fade active show" id="liton_product_grid">
									<div className="ltn__product-tab-content-inner ltn__product-grid-view">
										<div className="row">
											{/*
											<div className="col-lg-12">
												
												<div className="ltn__search-widget mb-30">
													<form action="#">
														<input type="text" name="search" placeholder="Aramak istediğiniz kelimeyi giriniz..." />
														<button type="submit"><i className="fas fa-search" /></button>
													</form>
												</div>
											</div>*/}
											{/* ltn__product-item */}
											<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
												<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
													<div className="product-img go-top">
														<Link to="/urun-detay"><img className='product-img-h' src={publicUrl + "assets/img/product/es-384.jpeg"} alt="#" /></Link>
														
													</div>
													<div className="product-info d-flex">
														
														<div className="product-price">
															<span>ES-384<label>/Otobüs Klimaları</label></span>
														</div>
														<div className="product-hover-action">
															<ul>
																<li>
																	<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
																		<i className="flaticon-expand" />
																	</a>
																</li>
															</ul>
														</div>
													</div>
													
												</div>
											</div>
											{/* ltn__product-item */}
											<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
												<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
													<div className="product-img go-top">
														<Link to="/urun-detay"><img className='product-img-h' src={publicUrl + "assets/img/product/es-356as.jpeg"} alt="#" /></Link>
														
													</div>
													<div className="product-info d-flex">
														
														<div className="product-price">
															<span>ES-356AS<label>/Savunma Sanayi</label></span>
														</div>
														<div className="product-hover-action">
															<ul>
																<li>
																	<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
																		<i className="flaticon-expand" />
																	</a>
																</li>
															</ul>
														</div>
													</div>
													
												</div>
											</div>
											{/* ltn__product-item */}
											<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
												<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
													<div className="product-img go-top">
														<Link to="/urun-detay"><img className='product-img-h' src={publicUrl + "assets/img/product/es-206.jpeg"} alt="#" /></Link>
														
													</div>
													<div className="product-info d-flex">
														
														<div className="product-price">
															<span>ES-206<label>/Raylı Sistemler</label></span>
														</div>
														<div className="product-hover-action">
															<ul>
																<li>
																	<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
																		<i className="flaticon-expand" />
																	</a>
																</li>
															</ul>
														</div>
													</div>
													
												</div>
											</div>
											{/*  */}
											{/* ltn__product-item */}
											<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
												<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
													<div className="product-img go-top">
														<Link to="/urun-detay"><img className='product-img-h' src={publicUrl + "assets/img/product/penguen-200.jpeg"} alt="#" /></Link>
													
													</div>
													<div className="product-info d-flex">
														
														<div className="product-price">
															<span>Penguen-200<label>/Araç Motorundan Tahrikli</label></span>
														</div>
														<div className="product-hover-action">
															<ul>
																<li>
																	<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
																		<i className="flaticon-expand" />
																	</a>
																</li>
															</ul>
														</div>
													</div>
													
												</div>
											</div>
											{/*  */}
										</div>
									</div>
								</div>
								
							</div>
							<div className="ltn__pagination-area text-center">
								<div className="ltn__pagination">
									<ul>
										<li><Link to="#"><i className="fas fa-angle-double-left" /></Link></li>
										<li><Link to="#">1</Link></li>
										<li className="active"><Link to="#">2</Link></li>
										<li><Link to="#">3</Link></li>
										<li><Link to="#">...</Link></li>
										<li><Link to="#">10</Link></li>
										<li><Link to="#"><i className="fas fa-angle-double-right" /></Link></li>
									</ul>
								</div>
							</div>
						</div>
						<Sidebar />
					</div>
				</div>
			</div>

			<div className="ltn__modal-area ltn__add-to-cart-modal-area">
				<div className="modal fade" id="liton_wishlist_modal" tabIndex={-1}>
					<div className="modal-dialog modal-md" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">×</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="ltn__quick-view-modal-inner">
									<div className="modal-product-item">
										<div className="row">
											<div className="col-12">
												<div className="modal-product-img">
													<img src={publicUrl + "assets/img/product/7.png"} alt="#" />
												</div>
												<div className="modal-product-info go-top">
													<h5><Link to="/product-details">Brake Conversion Kit</Link></h5>
													<p className="added-cart"><i className="fa fa-check-circle" />  Successfully added to your Wishlist</p>
													<div className="btn-wrapper">
														<Link to="/wishlist" className="theme-btn-1 btn btn-effect-1">View Wishlist</Link>
													</div>
												</div>
												{/* additional-info */}
												<div className="additional-info d-none">
													<p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
													<div className="payment-method">
														<img src={publicUrl + "assets/img/icons/payment.png"} alt="#" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="ltn__modal-area ltn__quick-view-modal-area">
				<div className="modal fade" id="quick_view_modal" tabIndex={-1}>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">×</span>
									{/* <i class="fas fa-times"></i> */}
								</button>
							</div>
							<div className="modal-body">
								<div className="ltn__quick-view-modal-inner">
									<div className="modal-product-item">
										<div className="row">
											<div className="col-lg-4 col-12">
												<div className="modal-product-img">
													<img src={publicUrl + "assets/img/product/es-384.jpeg"} alt="#" />
												</div>
												<h3>ES-384</h3>
													<div className="modal-product-meta ltn__product-details-menu-1">
														<ul>
															<li>
																<strong>Kategoriler:</strong>
																<span className="go-top">
																	<Link to="/blog">Frigorifik Soğutuclar</Link>
																	<Link to="/blog">Araç Motorundan Tahrikli</Link>
																</span>
															</li>
														</ul>
													</div>
											</div>
											<div className="col-lg-8 col-12">
												<div className="modal-product-info">
													
													
													<div className="property-detail-info-list section-bg-1 clearfix">
														<ul>
															<li><label>Model:</label> <span>ES-384 </span></li>
															<li><label>Ünite: </label> <span>Monoblok</span></li>
															<li><label>Yükseklik:</label> <span>195</span></li>
															<li><label>Genişlik:</label> <span>2190</span></li>
															<li><label>Boy:</label> <span>3250</span></li>
														</ul>
													</div>
													<div className="ltn__product-details-menu-2">
														<ul>
															<li>
																<a href="#" className="theme-btn-1 btn btn-effect-1" title="Add to Cart" data-bs-toggle="modal" data-bs-target="#add_to_cart_modal">
																	<i className="fas fa-shopping-cart" />
																	<span>Ürünü İncele</span>
																</a>
															</li>
														</ul>
													</div>
													
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="ltn__modal-area ltn__add-to-cart-modal-area">
				<div className="modal fade" id="add_to_cart_modal" tabIndex={-1}>
					<div className="modal-dialog modal-md" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">×</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="ltn__quick-view-modal-inner">
									<div className="modal-product-item">
										<div className="row">
											<div className="col-12">
												<div className="modal-product-img">
													<img src={publicUrl + "assets/img/product/1.png"} alt="#" />
												</div>
												<div className="modal-product-info go-top">
													<h5 className="go-top"><Link to="/product-details">Brake Conversion Kit</Link></h5>
													<p className="added-cart"><i className="fa fa-check-circle" />Successfully added to your Cart</p>
													<div className="btn-wrapper">
														<Link to="/cart" className="theme-btn-1 btn btn-effect-1">View Cart</Link>
														<Link to="/checkout" className="theme-btn-2 btn btn-effect-2">Checkout</Link>
													</div>
												</div>
												{/* additional-info */}
												<div className="additional-info d-none">
													<p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
													<div className="payment-method">
														<img src={publicUrl + "assets/img/icons/payment.png"} alt="#" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>

	}
}

export default ShopGridV1