import React, { Component } from "react";
import { API_URL } from "../../lib/axios";
import { useState } from "react";

const AboutV4 = (props) => {
  const [about, setAbout] = useState(props.data);
  const [image, setImage] = useState(props.image);

  let publicUrl = process.env.PUBLIC_URL + "/";

  if (!about) return null;

  return (
    <div
      id="i_hakkimizda"
      className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top"
    >
      {about && (
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h1 className="section-title">{about.title}</h1>
              </div>
            </div>
            {image && (
              <div className="col-lg-6 align-self-center">
                <div className="about-us-img-wrap about-img-left">
                  <img
                    src={API_URL + "/api/image?image=" + image + "&path=about"}
                    alt="About Us Image"
                  />
                </div>
              </div>
            )}
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div
                  dangerouslySetInnerHTML={{
                    __html: about.content,
                  }}
                  className="section-title-area ltn__section-title-2--- mb-20"
                ></div>
                {about.noteactive && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: about.note,
                    }}
                    className="ltn__callout bg-overlay-theme-05  mt-30"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutV4;
