import React, { useState, useEffect } from 'react';

function CookieBanner() {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const hasConsent = localStorage.getItem('onaylandi');
    if (hasConsent) {
      setShowBanner(false);
    }
  }, []);

  function allowCookies() {
    localStorage.setItem('onaylandi', 'true');
    setShowBanner(false);
  }

  function declineCookies() {
    setShowBanner(false);
  }

  if (localStorage.getItem("lang") === "tr") {
    var text = {
      text: "İçeriği, reklamları kişiselleştirmek ve site yönetimini analiz etmek için üçüncü taraf çerezlerini yürütmek.",
      accept: "Çerezleri Kabul Et",
      decline: "Çerezleri Reddet",
      more: "Daha Fazlası ...",
    }
  } else if (localStorage.getItem("lang") === "en") {
    var text = {
      text: "Execute third-party cookies to personalize content, advertisements and analyze site.",
      accept: "Accept All Cookies",
      decline: "Reject All Cookies",
      more: "More ...",
    }
  }

  return (
    <>
      {showBanner && (
          <div className="card-cookie p-3 cookie">
            <span>
            {text.text}
            </span>
            <a href={
              localStorage.getItem("lang") === "en" ? "/privacy" : "/gizlilik"
            }>
              {text.more}<i className="fa fa-angle-right ml-2"></i>
            </a>
            <div className="cookie-button">
              <span className="mr-3 decline" onClick={declineCookies}>
                {text.decline}
              </span>
              <span className="cookie-link" onClick={allowCookies}>
                {text.accept}
              </span>
            </div>
          </div>
      )}
    </>
  );
}

export default CookieBanner;
