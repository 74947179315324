import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import veriler from "./veriler1.json";
import axios, { API_URL } from "../../lib/axios";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const PDFPage = (props) => {
  const { id } = useParams();

  return (
<div style={{width: "100%", height: "100vh", margin:"0", overflow: "hidden"}}>
<object 
style={{width: "100%", height: "100vh"}}
data={API_URL+"/api/docs?docs=" + id + "&path=pdf/inves"}
 type="application/pdf">   </object>
</div>
  );
};

export default PDFPage;


