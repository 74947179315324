import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../../Added/Table";

import axios, { API_URL } from "../../lib/axios";
import { useState } from "react";
import { useEffect } from "react";

const UpcomingProductV1 = (props) => {
  const [data, setData] = useState(props.topProduct);
  const [datalang, setDatalang] = useState(props.topProductLang);
  const [product, setProduct] = useState(props.product);

  function componentDidMount() {
    const $ = window.$;
    if (data && product) {
      $(".ltn__upcoming-project-slider-1-active").slick({
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow:
          '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
        nextArrow:
          '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
        responsive: [
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 580,
            settings: {
              arrows: false,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    }
  }

  useEffect(() => {
    componentDidMount();
  }, []);

  let publicUrl = API_URL + "/";

  if (!data) return null;

  let imgsrc;

  if (data.image) {
    imgsrc =
      API_URL + "/api/image?image=" + data.image + "&path=topproductcompolent";

  }

  return (
    <div
      className="ltn__upcoming-project-area section-bg-1--- bg-image-top pt-115 pb-65"
      style={{
        backgroundImage: `url(${imgsrc})`,
        backgroundSize: "cover",
      }}
      data-bs-bg={imgsrc && imgsrc}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center---">
              <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color--- white-color">
                {datalang.subtitle}
              </h6>
              <h1
                className="section-title  white-color"
                dangerouslySetInnerHTML={{
                  __html: datalang.title,
                }}
              ></h1>
            </div>
          </div>
        </div>
        <div className="row ltn__upcoming-project-slider-1-active slick-arrow-3">
          {product.map((item) => {
            return (
              <div className="col-lg-12" key={item.id}>
                <div className="ltn__upcoming-project-item">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="ltn__upcoming-project-img">
                        <img
                          src={
                            API_URL +
                            "/api/image?image=" +
                            item.image +
                            "&path=product"
                          }
                          alt="#"
                        />
                      </div>
                      <h6 className="section-subtitle ltn__secondary-color mb-0 mt-5">
                        {item.category.categorylang[0].title}
                      </h6>
                      <h1 className="mb-30">{item.productlang[0].title}</h1>
                      <div className="btn-wrapper animated go-top">
                        <Link
                          to={"/urun-detay/" + item.productlang[0].sef}
                          className="theme-btn-1 btn btn-effect-1 mb-3"
                        >
                        {localStorage.getItem("lang") == "tr" ? "Ürünü İncele" : "View Product"}
                        </Link>
                      </div>
                    </div>

                    <div className="col-lg-9 section-bg-1">
                      <div className=" ltn__menu-widget my-3">
                        <Table data={item.productlang[0].detail} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* upcoming-project-item */}
          {/* <div className="col-lg-12">
              <div className="ltn__upcoming-project-item">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="ltn__upcoming-project-img">
                      <img
                        src={publicUrl + "assets/img/product/e-series.webp"}
                        alt="#"
                      />
                    </div>
                  </div>

                  <div className="col-lg-9 section-bg-1">
                    <div className="ltn__upcoming-project-info ltn__menu-widget">
                      <h6 className="section-subtitle ltn__secondary-color mb-0">
                        e-series
                      </h6>
                      <h1 className="mb-30">ES50EC</h1>
                      <ul className="mt">
                        <li>
                          Soğutma Kapasitesi (Dış Ortam Sıcaklığı +35°C):{" "}
                          <span>3,5 kW</span>
                        </li>
                        <li>
                          Soğutucu Akışkan: <span>R134a</span>
                        </li>
                        <li>
                          Ağırlık: <span>45 kg</span>
                        </li>
                        <li>
                          Ünite Ölçüleri: <span>1302x810x270 mm </span>
                        </li>
                        <li>
                          Hava Akışı: <span>750 m3/h at 0 Pa</span>
                        </li>
                        <li>
                          Güç Tüketimi: <span>24 VDC-45A</span>
                        </li>
                      </ul>
                      <div className="btn-wrapper animated go-top">
                        <Link
                          to="/es50ec"
                          className="theme-btn-1 btn btn-effect-1"
                        >
                          Ürünü İncele
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default UpcomingProductV1;
