import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import axios, { API_URL } from "../../lib/axios";
import { useEffect } from "react";
import { useState } from "react";

const VideoV2 = (props) => {
  const [page, setPage] = useState(props.page);
  const [data, setData] = useState(props.data);

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  useEffect(() => {
    if (page) {
      console.log(page);
      console.log(data);
      if (page.video?.includes("youtube.com")) {
        setPage({ ...page, video: page.video.replace("watch?v=", "embed/") });
      } else if (page.video?.includes("youtu.be")) {
        setPage({
          ...page,
          video: page.video.replace("youtu.be/", "youtube.com/embed/"),
        });
      } else {
        return;
      }
    }
  }, [props.data]);

  useEffect(() => {
    const $ = window.$;
    if (page?.video?.includes("/embed/")) {
      $("a[data-rel^=lightcase]").lightcase({
        transition:
          "elastic" /* none, fade, fadeInline, elastic, scrollTop, scrollRight, scrollBottom, scrollLeft, scrollHorizontal and scrollVertical */,
        swipe: true,
        maxWidth: 1500,
        maxHeight: 750,
      });
    }
  }, [page]);

  if (!page || !data) return null;

  return (
    <div className="ltn__about-us-area section-bg-1 bg-image-right-before pt-120 pb-90">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2--- mb-20">
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                  {data.subtitle}
                </h6>
                <h1 className="section-title">{data.title}</h1>
                <p>{data.content}</p>
              </div>
              <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                {data?.list &&
                  data?.list?.map((list, index) => (
                    <li key={index}>
                      <i className="icon-done" />
                      {list}
                    </li>
                  ))}
              </ul>
              <div className="  ltn__animation-pulse2 text-center mt-30">
                <a
                  className="ltn__video-play-btn bg-white--- ltn__secondary-bg"
                  href={page.video}
                  data-rel="lightcase"
                >
                  <i className="icon-play  ltn__secondary-color--- white-color" />
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 align-self-center">
            <div
              className="about-us-img-wrap about-img-left"
              style={{
                backgroundImage: `url(${
                  API_URL + "/api/image&image=" + page.image + "&path=whyus"
                })`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoV2;
