import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const Error = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div
      className="ltn__404-area ltn__404-area-1"
      style={{ marginTop: "100px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="error-404-inner text-center">
              <div className="error-img mb-30">
                <img
                  src={publicUrl + "assets/img/others/error-1.png"}
                  alt="#"
                />
              </div>
              <h1 className="error-404-title d-none">404</h1>
              <h2>Sayfa Bulunamadı!</h2>
              {/* <h3>Oops! Looks like something going rong</h3> */}
              <p>
                Aradığınız sayfa mevcut değil. Taşınmış veya silinmiş olabilir.
              </p>
              <div className="btn-wrapper go-top">
                <Link to="/" className="btn btn-transparent">
                  <i className="fas fa-long-arrow-alt-left" /> Anasayfaya Dön
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
