import React, { Component, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import axios, { API_URL } from "../../lib/axios";

const PortfolioV1 = (props) => {
  const { state } = useLocation();
  const [selected, setSelected] = useState("tumu");

  const [categories, setCategories] = useState([]);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    setSelected("tumu");
    if (state && state.tab) {
      setSelected(state.tab);
    }

    const sortByIndexAndName = (a, b) => {
      // Index değeri var mı kontrol et, yoksa sona al
      if (a.index === null || a.index === undefined || a.index <= 0) return 1;
      if (b.index === null || b.index === undefined || b.index <= 0) return -1;
    
      // İlk olarak index değerine göre sırala
      if (a.index !== undefined && b.index !== undefined) {
        if (a.index !== b.index) {
          return a.index - b.index; // Küçükten büyüğe doğru sırala
        } else {
          // Index değerleri eşitse isimlere göre alfabetik sırala
          if (a.productlang[0].title && b.productlang[0].title) {
            return a.productlang[0].title.localeCompare(b.productlang[0].title); // isimlere göre sırala
          }
        }
      }
    
      // Varsayılan olarak a ve b eşit olmalı
      return 0;
    }
    

    Promise.all([
      axios
        .get(`/category/frontend?code=${localStorage.getItem("lang")}`)
        .then((response) => {
          if (response.data.category) {
            setCategories(response.data.category);
          }
        }),

      axios
        .get(`/product/frontend?code=${localStorage.getItem("lang")}`)
        .then((response) => {
          if (response.data.product) {
            var sortedProducts = response.data.product.sort(sortByIndexAndName);
            setProducts(sortedProducts);
            console.log(sortedProducts);
          }
        }),
    ]);
  }, [state]);

  let publicUrl = API_URL + "/";
  let CustomClass = props.CustomClass ? props.CustomClass : "";

  if (products.length > 0 && categories.length > 0) {
    return (
      <>
        <div
          className={"ltn__apartments-plan-area pt-115--- pb-70 " + CustomClass}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color"></h6>
                  <h1 className="section-title">
                    {
                      localStorage.getItem("lang") === "en" ? "Our Products" : "Ürünlerimiz"
                    }
                  </h1>
                </div>
                <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
                  <div className="nav">
                    <a
                      onClick={() => setSelected("tumu")}
                      className={`${selected == "tumu" ? " active show " : ""}`}
                      data-bs-toggle="tab"
                      href="#liton_tab_3_1"
                    >
                      {localStorage.getItem("lang") === "en" ? "All" : "Tümü"}
                    </a>

                    {categories &&
                      categories.length > 0 &&
                      categories.map((category, index) => (
                        <a
                          key={index}
                          onClick={() =>
                            setSelected(category.categorylang[0].sef)
                          }
                          className={`${
                            selected == category.categorylang[0].sef
                              ? " active show "
                              : ""
                          }`}
                          data-bs-toggle="tab"
                          href={category.categorylang[0].sef}
                        >
                          {category.display ? (
                            <img
                              src={
                                API_URL +
                                "/api/image?image=" +
                                category.image +
                                "&path=category"
                              }
                              style={{ maxWidth: "100px" }}
                              alt="Image"
                            />
                          ) : (
                            category.categorylang[0].title
                          )}
                        </a>
                      ))}

                    {/* <a
                    onClick={() => setSelected("ticari")}
                    className={`${selected == "ticari" ? " active show " : ""}`}
                    data-bs-toggle="tab"
                    href="#liton_tab_3_2"
                  >
                    Ticari Araç Klimaları
                  </a>
                  <a
                    onClick={() => setSelected("frigorifik")}
                    className={`${
                      selected == "frigorifik" ? " active show " : ""
                    }`}
                    data-bs-toggle="tab"
                    href="#liton_tab_3_3"
                  >
                    Frigorifik Soğutucular
                  </a>
                  <a
                    onClick={() => setSelected("raylı")}
                    className={`${selected == "raylı" ? " active show " : ""}`}
                    data-bs-toggle="tab"
                    href="#liton_tab_3_4"
                  >
                    Raylı Sistemler
                  </a>
                  <a
                    onClick={() => setSelected("savunma")}
                    className={`${
                      selected == "savunma" ? " active show " : ""
                    }`}
                    data-bs-toggle="tab"
                    href="#liton_tab_3_5"
                  >
                    Savunma Sanayi
                  </a>
                  <a
                    onClick={() => setSelected("resim")}
                    className={`${selected == "resim" ? " active show " : ""}`}
                    data-bs-toggle="tab"
                    href="#liton_tab_3_6"
                  >
                    <img
                      src={publicUrl + "assets/img/icons/eseries.png"}
                      style={{ maxWidth: "100px" }}
                      alt="Image"
                    />
                  </a> */}
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    className={`${
                      selected == "tumu" ? " active show " : ""
                    } tab-pane fade`}
                    id="liton_tab_3_1"
                  >
                    <div className="ltn__product-tab-content-inner">
                      <div className="row">
                        {products &&
                          products.length > 0 &&
                          products.map((product, index) => (
                            <div
                              key={index}
                              className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12"
                            >
                              <div className="ltn__gallery-item-inner">
                                <div className="ltn__gallery-item-img">
                                  <Link
                                    to={localStorage.getItem("lang") == "tr" ? `/urun-detay/${product.productlang[0].sef}` : `/product-detail/${product.productlang[0].sef}`}
                                  >
                                    <img
                                      src={
                                        API_URL +
                                        "/api/image?image=" +
                                        product.image +
                                        "&path=product"
                                      }
                                      alt="Image"
                                    />
                                  </Link>
                                </div>

                                <div className="ltn__gallery-item-info">
                                  <h4 className="go-top">
                                    <Link
                                      to={localStorage.getItem("lang") == "tr" ? `/urun-detay/${product.productlang[0].sef}` : `/product-detail/${product.productlang[0].sef}`}
                                    >
                                      {product.productlang[0].title}
                                    </Link>
                                  </h4>
                                  <p>
                                    {product.category.categorylang[0].title}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        {/* <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
                        <div className="ltn__gallery-item-inner">
                          <div className="ltn__gallery-item-img">
                            <Link to="/urun-detay">
                              <img
                                src={
                                  publicUrl + "assets/img/product/es438.jpeg"
                                }
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__gallery-item-info">
                            <h4 className="go-top">
                              <Link to="/urun-detay">ES-438 </Link>
                            </h4>
                            <p>Otobüs Klimaları</p>
                          </div>
                        </div>
                      </div>
                      <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
                        <div className="ltn__gallery-item-inner">
                          <div className="ltn__gallery-item-img">
                            <Link to="/penguen200">
                              <img
                                src={
                                  publicUrl +
                                  "assets/img/product/penguen-200.jpeg"
                                }
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__gallery-item-info">
                            <h4 className="go-top">
                              <Link to="/penguen200">Penguen 200 </Link>
                            </h4>
                            <p>Frigorifik Soğutucular</p>
                          </div>
                        </div>
                      </div>
                      <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
                        <div className="ltn__gallery-item-inner">
                          <div className="ltn__gallery-item-img">
                            <Link to="/penguen485">
                              <img
                                src={
                                  publicUrl +
                                  "assets/img/product/penguen-485.jpeg"
                                }
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__gallery-item-info">
                            <h4 className="go-top">
                              <Link to="/penguen485">Penguen 485 </Link>
                            </h4>
                            <p>Frigorifik Soğutucular</p>
                          </div>
                        </div>
                      </div>
                      <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
                        <div className="ltn__gallery-item-inner">
                          <div className="ltn__gallery-item-img">
                            <Link to="/es206">
                              <img
                                src={
                                  publicUrl + "assets/img/product/es-206.jpeg"
                                }
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__gallery-item-info">
                            <h4 className="go-top">
                              <Link to="/es206">ES-206 </Link>
                            </h4>
                            <p>Raylı Sistemler</p>
                          </div>
                        </div>
                      </div>
                      <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
                        <div className="ltn__gallery-item-inner">
                          <div className="ltn__gallery-item-img">
                            <Link to="/es356as">
                              <img
                                src={
                                  publicUrl + "assets/img/product/es-356as.jpeg"
                                }
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__gallery-item-info">
                            <h4 className="go-top">
                              <Link to="/es356as">ES-356AS </Link>
                            </h4>
                            <p>Savunma Sanayi</p>
                          </div>
                        </div>
                      </div>
                      <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
                        <div className="ltn__gallery-item-inner">
                          <div className="ltn__gallery-item-img">
                            <Link to="/es50ec">
                              <img
                                src={
                                  publicUrl + "assets/img/product/e-series.webp"
                                }
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__gallery-item-info">
                            <h4 className="go-top">
                              <Link to="/es50ec">ES-50EC </Link>
                            </h4>
                            <p>E-Series</p>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  {categories &&
                    categories.length > 0 &&
                    categories.map((category, index) => {

                      const sortByIndexAndName = (a, b) => {
      // Index değeri var mı kontrol et, yoksa sona al
      if (a.index === null || a.index === undefined || a.index <= 0) return 1;
      if (b.index === null || b.index === undefined || b.index <= 0) return -1;
    
      // İlk olarak index değerine göre sırala
      if (a.index !== undefined && b.index !== undefined) {
        if (a.index !== b.index) {
          return a.index - b.index; // Küçükten büyüğe doğru sırala
        } else {
          // Index değerleri eşitse isimlere göre alfabetik sırala
          if (a.productlang[0].title && b.productlang[0].title) {
            return a.productlang[0].title.localeCompare(b.productlang[0].title); // isimlere göre sırala
          }
        }
      }
    
      // Varsayılan olarak a ve b eşit olmalı
      return 0;
    }

                      var sortedProducts = category.products.sort(sortByIndexAndName);

                      return (
                      <div
                        key={index}
                        className={`${
                          selected == category.categorylang[0].sef
                            ? " active show "
                            : ""
                        } tab-pane fade`}
                        id={category.categorylang[0].sef}
                      >
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            {sortedProducts &&
                              sortedProducts.length > 0 &&
                              sortedProducts.map((product, index) => {
                                return (
                                <div
                                  key={index}
                                  className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12"
                                >
                                  <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                      <Link
                                        to={localStorage.getItem("lang") == "tr" ? `/urun-detay/${product.productlang[0].sef}` : `/product-detail/${product.productlang[0].sef}`}
                                      >
                                        <img
                                          src={
                                            API_URL +
                                            "/api/image?image=" +
                                            product.image +
                                            "&path=product"
                                          }
                                          alt="Image"
                                        />
                                      </Link>
                                    </div>

                                    <div className="ltn__gallery-item-info">
                                      <h4 className="go-top">
                                        <Link
                                          to={localStorage.getItem("lang") == "tr" ? `/urun-detay/${product.productlang[0].sef}` : `/product-detail/${product.productlang[0].sef}`}
                                        >
                                          {product.productlang[0].title}
                                        </Link>
                                      </h4>
                                      <p>{category.categorylang[0].title}</p>
                                    </div>
                                  </div>
                                </div>
                              )})}

                          </div>
                        </div>
                      </div>
                    )})}

                  {/* <div
                  className={`${
                    selected == "ticari" ? " active show " : ""
                  } tab-pane fade`}
                  id="liton_tab_3_2"
                >
                  <div className="ltn__apartments-tab-content-inner">
                    <div className="row">
                      <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
                        <div className="ltn__gallery-item-inner">
                          <div className="ltn__gallery-item-img">
                            <Link to="/urun-detay">
                              <img
                                src={
                                  publicUrl + "assets/img/product/es438.jpeg"
                                }
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__gallery-item-info">
                            <h4 className="go-top">
                              <Link to="/urun-detay">ES-438 </Link>
                            </h4>
                            <p>Otobüs Klimaları</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    selected == "frigorifik" ? " active show " : ""
                  } tab-pane fade`}
                  id="liton_tab_3_3"
                >
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
                        <div className="ltn__gallery-item-inner">
                          <div className="ltn__gallery-item-img">
                            <Link to="/penguen200">
                              <img
                                src={
                                  publicUrl +
                                  "assets/img/product/penguen-200.jpeg"
                                }
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__gallery-item-info">
                            <h4 className="go-top">
                              <Link to="/penguen200">Penguen 200 </Link>
                            </h4>
                            <p>Frigorifik Soğutucular</p>
                          </div>
                        </div>
                      </div>
                      <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
                        <div className="ltn__gallery-item-inner">
                          <div className="ltn__gallery-item-img">
                            <Link to="/penguen485">
                              <img
                                src={
                                  publicUrl +
                                  "assets/img/product/penguen-485.jpeg"
                                }
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__gallery-item-info">
                            <h4 className="go-top">
                              <Link to="/penguen485">Penguen 485 </Link>
                            </h4>
                            <p>Frigorifik Soğutucular</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    selected == "raylı" ? " active show " : ""
                  } tab-pane fade`}
                  id="liton_tab_3_4"
                >
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
                        <div className="ltn__gallery-item-inner">
                          <div className="ltn__gallery-item-img">
                            <Link to="/es206">
                              <img
                                src={
                                  publicUrl + "assets/img/product/es-206.jpeg"
                                }
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__gallery-item-info">
                            <h4 className="go-top">
                              <Link to="/es206">ES-206 </Link>
                            </h4>
                            <p>Raylı Sistemler</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    selected == "savunma" ? " active show " : ""
                  } tab-pane fade`}
                  id="liton_tab_3_5"
                >
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
                        <div className="ltn__gallery-item-inner">
                          <div className="ltn__gallery-item-img">
                            <Link to="/es356as">
                              <img
                                src={
                                  publicUrl + "assets/img/product/es-356as.jpeg"
                                }
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__gallery-item-info">
                            <h4 className="go-top">
                              <Link to="/es356as">ES-356AS </Link>
                            </h4>
                            <p>Savunma Sanayi</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    selected == "resim" ? " active show " : ""
                  } tab-pane fade`}
                  id="liton_tab_3_6"
                >
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
                        <div className="ltn__gallery-item-inner">
                          <div className="ltn__gallery-item-img">
                            <Link to="/es50ec">
                              <img
                                src={
                                  publicUrl + "assets/img/product/e-series.webp"
                                }
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__gallery-item-info">
                            <h4 className="go-top">
                              <Link to="/es50ec">ES-50EC </Link>
                            </h4>
                            <p>E-Series</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
};

export default PortfolioV1;
