import React from "react";
import { Link } from "react-router-dom";
import axios, { API_URL } from "../../lib/axios";
import { useEffect } from "react";
import { useState } from "react";
import { ArrayContext } from "../../Added/Context";
import { useContext } from "react";
import LanguageSelector from "../section-components/languageSelector";

const NavbarV5 = (props) => {
  const {
    navbarArray,
    socialArray,
    categoriesArray,
    footerPage,
    setFooterPage,
    setCategoriesArray,
    setNavbarArray,
    setSocialArray,
  } = useContext(ArrayContext);
  const [data, setData] = useState(navbarArray);
  const [social, setSocial] = useState(socialArray);
  const [categorys, setCategorys] = useState(categoriesArray);

  const [topProductComponentLang, setTopProductComponentLang] = useState(null);
  const [languageActive, setLanguageActive] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("lang") === null) {
      localStorage.setItem("lang", "tr");
    }

      axios(`/languageActive/frontend`)
      .then((data) => {
        setLanguageActive(data.data.active);
      })

    if (!categorys) {
      axios(`/category/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setCategorys(data.data.category);
          setCategoriesArray(data.data.category);
        })
        .catch((error) => {
          console.log("Hata:", error);
        });
    }

    if (!socialArray) {
      axios(`/social/frontend`)
        .then((response) => {
          setSocial(response.data.social);
          setSocialArray(response.data.social);
        })
        .catch((error) => {
          console.log("Hata:", error);
        });
    }

    if (!data) {
      axios(`/language/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setData(data.data.language);
          setNavbarArray(data.data.language);
        })
        .catch((error) => {
          console.log("Hata:", error);
        });
    }

    if (!footerPage) {
      axios(`/setting/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setFooterPage(data.data.settings);
        })
        .catch((error) => {
          console.log("Hata:", error);
        });
    }

    async function getTitle() {
      await axios(
        `/topproductcompolent/frontend?code=${localStorage.getItem("lang")}`
      )
        .then((data) => {
          setTopProductComponentLang(data.data.topProductLang[0]);
        })
        .catch((error) => {
          console.log("Hata:", error);
        });
    }

    getTitle();
  }, []);

  useEffect(() => {
    if (footerPage?.favicon) {
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href =
        API_URL +
        "/api/image?image=" +
        footerPage.favicon.replaceAll(" ", "%20") +
        "&path=favicon";
    }
  }, [footerPage]);

  useEffect(() => {
    const $ = window.$;
    const $body = $("body");
    if (data && social && categorys && footerPage && topProductComponentLang) {
      (function () {
        var $ltn__utilizeToggle = $(".ltn__utilize-toggle"),
          $ltn__utilize = $(".ltn__utilize"),
          $ltn__utilizeOverlay = $(".ltn__utilize-overlay"),
          $mobileMenuToggle = $(".mobile-menu-toggle");
        $ltn__utilizeToggle.on("click", function (e) {
          e.preventDefault();
          var $this = $(this),
            $target = $this.attr("href");
          $body.addClass("ltn__utilize-open");
          $($target).addClass("ltn__utilize-open");
          $ltn__utilizeOverlay.fadeIn();
          if ($this.parent().hasClass("mobile-menu-toggle")) {
            $this.addClass("close");
          }
        });

        $(".ltn__utilize-close, .ltn__utilize-overlay").on(
          "click",
          function (e) {
            e.preventDefault();
            $body.removeClass("ltn__utilize-open");
            $ltn__utilize.removeClass("ltn__utilize-open");
            $ltn__utilizeOverlay.fadeOut();
            $mobileMenuToggle.find("a").removeClass("close");
          }
        );
      })();

      /* ------------------------------------
                Utilize Menu
            ----------------------------------- */
      function mobileltn__utilizeMenu() {
        var $ltn__utilizeNav = $(".ltn__utilize-menu, .overlay-menu"),
          $ltn__utilizeNavSubMenu = $ltn__utilizeNav.find(".sub-menu");

        /*Add Toggle Button With Off Canvas Sub Menu*/
        $ltn__utilizeNavSubMenu
          .parent()
          .prepend('<span class="menu-expand"></span>');

        /*Category Sub Menu Toggle*/
        $ltn__utilizeNav.on("click", "li a, .menu-expand", function (e) {
          var $this = $(this);
          if ($this.attr("href") === "#" || $this.hasClass("menu-expand")) {
            e.preventDefault();
            if ($this.siblings("ul:visible").length) {
              $this.parent("li").removeClass("active");
              $this.siblings("ul").slideUp();
              $this.parent("li").find("li").removeClass("active");
              $this.parent("li").find("ul:visible").slideUp();
            } else {
              $this.parent("li").addClass("active");
              $this
                .closest("li")
                .siblings("li")
                .removeClass("active")
                .find("li")
                .removeClass("active");
              $this.closest("li").siblings("li").find("ul:visible").slideUp();
              $this.siblings("ul").slideDown();
            }
          }
        });
      }
      mobileltn__utilizeMenu();
    }
  }, [data, social, categorys, footerPage, topProductComponentLang]);

  const publicUrl = process.env.PUBLIC_URL + "/";

  if (localStorage.getItem("lang") === "tr") {
    var menuItems = [
      {
        label: "Kurumsal",
        url: "/kurumsal",
      },
      {
        label: topProductComponentLang && topProductComponentLang.pageTitle,
        url: "/urunler",
      },
      {
        label: "Satış & Servis",
        url: "/satis-servis",
      },
      {
        label: "Haberler",
        url: "/haberler",
      },
      {
        label: "İletişim",
        url: "/iletisim",
      },
      {
        label: "Yatırımcı İlişkileri",
        url: "/yatirimci-iliskileri",
      },
    ];
  } else {
    var menuItems = [
      {
        label: "Corporate",
        url: "/corporate",
      },
      {
        label: topProductComponentLang && topProductComponentLang.pageTitle,
        url: "/products",
      },
      {
        label: "Sales & Service",
        url: "/sale-service",
      },
      {
        label: "News",
        url: "/news",
      },
      {
        label: "Contact",
        url: "/contact",
      },
    ];
  }

  if (!data || !categorys || !socialArray || !topProductComponentLang)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );

  return (
    <div style={{zIndex:1000, position:'relative'}}>
      {/* HEADER AREA START (header-3) */}
      <header className="ltn__header-area ltn__header-3 section-bg-6---">
        {/* ltn__header-middle-area start */}
        <div className="ltn__header-middle-area">
          <div className="container">
            <div className="row">
              <div className="col">
                {footerPage?.logo && (
                  <div className="site-logo">
                    <Link
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "auto" })
                      }
                      to="/"
                    >
                      <img
                        src={
                          API_URL +
                          "/api/image?image=" +
                          footerPage.logo +
                          "&path=logo"
                        }
                        alt="Logo"
                      />
                    </Link>
                  </div>
                )}
              </div>

              <div className="col">
                {/* header-options */}
                <div className="ltn__header-options">
                  <ul>
                    <li>
                      {/* ltn__language-menu */}
                      <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                        {/* <ul>
                          <li>
                            {data.map((item) => {
                              if (item.code == localStorage.getItem("lang")) {
                                return (
                                  <a
                                    href="#"
                                    key={item.id}
                                    className="dropdown-toggle"
                                  >
                                    <span className="active-currency">
                                      {item.name}
                                    </span>
                                  </a>
                                );
                              }
                            })}
                            <ul>
                              {data.map((item) => {
                                if (item.code == localStorage.getItem("lang")) {
                                  return null;
                                }
                                 else {
                                  return (
                                    <li key={item.id}>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "lang",
                                            item.code
                                          );
                                          window.location.reload();
                                        }}
                                      >
                                        {item.name}
                                      </Link>
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </li>
                        </ul> */}
                      </div>
                    </li>
                    <li className="special-link d-lg-inline-block d-none">
                      <a
                        href="https://b2b.safkar.com/login.aspx"
                        target={"_blank"}
                      >
                        {localStorage.getItem("lang") === "tr"
                          ? "Bayi Girişi"
                          : "Dealer Login"}
                      </a>
                    </li>
                    <li>
                    { languageActive == true &&
                    <LanguageSelector />}
                    </li>
                  </ul>
                  <div className="mobile-menu-toggle d-xl-none d-lg-none">
                    <a
                      href="#ltn__utilize-mobile-menu"
                      className="ltn__utilize-toggle"
                    >
                      <svg viewBox="0 0 800 600">
                        <path
                          d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                          id="top"
                        />
                        <path d="M300,320 L540,320" id="middle" />
                        <path
                          d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                          id="bottom"
                          transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ltn__header-middle-area end */}
        {/* header-bottom-area start */}
        <div className="header-bottom-area ltn__border-top ltn__header-sticky  ltn__sticky-bg-white ltn__primary-bg---- menu-color-white---- d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="col header-menu-column justify-content-center">
                <div className="sticky-logo">
                  <div className="site-logo">
                    <Link
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "auto" })
                      }
                      to="/"
                    >
                      {footerPage?.logo && (
                        <img
                          src={
                            API_URL +
                            "/api/image?image=" +
                            footerPage.logo +
                            "&path=logo"
                          }
                          alt="Logo"
                        />
                      )}
                    </Link>
                  </div>
                </div>
                <div className="header-menu header-menu-2">
                  <nav>
                    <div className="ltn__main-menu">
                      <ul>
                        {/*
								<li className="menu-icon"><Link to="/">Home</Link>
									<ul className="sub-menu menu-pages-img-show">

									<li>
										<Link to="/">Home Style 01</Link>
										<img src={publicUrl+"assets/img/home-demos/home-1.jpg"} alt="#" />
									</li>
									<li>
										<Link to="/home-v2">Home Style 02</Link>
										<img src={publicUrl+"assets/img/home-demos/home-2.jpg"} alt="#" />
									</li>
									<li>
										<Link to="/home-v3">Home Style 03</Link>
										<img src={publicUrl+"assets/img/home-demos/home-3.jpg"} alt="#" />
									</li>
									<li>
									<Link to="/home-v4">Home Style 04</Link>
									<img src={publicUrl+"assets/img/home-demos/home-4.jpg" } alt="#" /> 
									</li>
									<li>
										<Link to="/home-v5">Home Style 05 <span className="menu-item-badge">video</span></Link>
										<img src={publicUrl+"assets/img/home-demos/home-5.jpg" } alt="#" /> 
									</li>
									<li>
										<Link to="/home-v6">Home Style 06</Link>
										<img src={publicUrl+"assets/img/home-demos/home-6.jpg" } alt="#" /> 
									</li>
									<li>
										<Link to="/home-v7">Home Style 07</Link>
										<img src={publicUrl+"assets/img/home-demos/home-7.jpg" } alt="#" /> 
									</li>
									<li>
										<Link to="/home-v8">Home Style 08</Link>
										<img src={publicUrl+"assets/img/home-demos/home-8.jpg" } alt="#" /> 
									</li>
									<li>
										<Link to="/home-v9">Home Style 09</Link>
										<img src={publicUrl+"assets/img/home-demos/home-9.jpg" } alt="#" /> 
									</li>
									<li>
										<Link to="/home-v10">Home Style 10</Link>
										<img src={publicUrl+"assets/img/home-demos/home-11.jpg"}  alt="#" />
									</li>
									
									</ul>
								</li>
								*/}
                        <li>
                          <Link to={menuItems[0].url}>{menuItems[0].label}</Link>
                        </li>
                        <li className="menu-icon">
                          <Link
                            onClick={() =>
                              window.scrollTo({ top: 0, behavior: "auto" })
                            }
                            to={menuItems[1].url}
                          >
                            {menuItems[1].label}
                          </Link>
                          <ul>
                            {categorys.map((item) => {
                            var menuUrl = menuItems[1].url
                              return (
                                <li key={item.id}>
                                  <Link
                                    to={{
                                      pathname: menuUrl,
                                      state: {
                                        tab: item.categorylang[0].sef,
                                      },
                                    }}
                                  >
                                    {item.categorylang[0].title}
                                  </Link>
                                </li>
                              );
                            })}
                            {/* <li>
                                <Link
                                  to={{
                                    pathname: "/urunler",
                                    state: { tab: "ticari" },
                                  }}
                                >
                                  Ticari Araç Klimaları
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: "/urunler",
                                    state: { tab: "frigorifik" },
                                  }}
                                >
                                  Frigorifik Soğutucular
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: "/urunler",
                                    state: { tab: "raylı" },
                                  }}
                                >
                                  Raylı Sistemler
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: "/urunler",
                                    state: { tab: "savunma" },
                                  }}
                                >
                                  Savunma Sanayi
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: "/urunler",
                                    state: { tab: "resim" },
                                  }}
                                >
                                  E-series
                                </Link>
                              </li> */}
                          </ul>
                        </li>
                        <li>
                          <Link
                            onClick={() =>
                              window.scrollTo({ top: 0, behavior: "auto" })
                            }
                            to={menuItems[2].url}
                          >
                            {menuItems[2].label}
                          </Link>
                        </li>
                        {/*}
								<li className="menu-icon"><Link to="/shop">Shop</Link>
									<ul>
										<li><Link to="/shop">Shop</Link></li>
										<li><Link to="/shop-grid">Shop Grid</Link></li>
										<li><Link to="/shop-left-sidebar">Shop Left sidebar</Link></li>
										<li><Link to="/shop-right-sidebar">Shop Right sidebar</Link></li>
										<li><Link to="/product-details">Shop Details</Link></li>
										<li><Link to="/cart">Cart</Link></li>
										<li><Link to="/checkout">Checkout</Link></li>
										<li><Link to="/my-account">My Account</Link></li>
										<li><Link to="/login">Sign in</Link></li>
										<li><Link to="/register">Register</Link></li>
									</ul>
								</li>
								*/}
                        <li>
                          <Link
                            onClick={() =>
                              window.scrollTo({ top: 0, behavior: "auto" })
                            }
                            to={menuItems[3].url}
                          >
                            {menuItems[3].label}
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() =>
                              window.scrollTo({ top: 0, behavior: "auto" })
                            }
                            to={menuItems[4].url}
                          >
                            {menuItems[4].label}
                          </Link>
                        </li>

                        {menuItems[5] && (
                          <li>
                            <Link
                              onClick={() =>
                                window.scrollTo({ top: 0, behavior: "auto" })
                              }
                              to={menuItems[5].url}
                            >
                              {menuItems[5].label}
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* header-bottom-area end */}
      </header>
      {/* HEADER AREA END */}

      <div
        id="ltn__utilize-mobile-menu"
        className="ltn__utilize ltn__utilize-mobile-menu"
      >
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
          <div className="ltn__utilize-menu-head">
            <div className="site-logo">
              <Link
                onClick={() => window.scrollTo({ top: 0, behavior: "auto" })}
                to="/"
              >
                <img src={publicUrl + "assets/img/logo.png"} alt="Logo" />
              </Link>
            </div>
            <button className="ltn__utilize-close">×</button>
          </div>

          <div className="ltn__utilize-menu">
            <ul>
              <li>
                <Link
                  onClick={() => window.scrollTo({ top: 0, behavior: "auto" })}
                  to={menuItems[0].url}
                >
                  {menuItems[0].label}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => window.scrollTo({ top: 0, behavior: "auto" })}
                  to={menuItems[1].url}
                >
                  {menuItems[1].label}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => window.scrollTo({ top: 0, behavior: "auto" })}
                  to={menuItems[2].url}
                >
                  {menuItems[2].label}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => window.scrollTo({ top: 0, behavior: "auto" })}
                  to={menuItems[3].url}
                >
                  {menuItems[3].label}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => window.scrollTo({ top: 0, behavior: "auto" })}
                  to={menuItems[4].url}
                >
                  {menuItems[4].label}
                </Link>
              </li>
              {menuItems[5] && (
                <li>
                  <Link
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "auto" })
                    }
                    to={menuItems[5].url}
                  >
                    {menuItems[5].label}
                  </Link>
                </li>
              )}
              <li className="special-link">
                <a href="https://b2b.safkar.com/login.aspx" target={"_blank"}>
                  {localStorage.getItem("lang") === "tr"
                    ? "Bayi Girişi"
                    : "Dealer Login"}
                </a>
              </li>
            </ul>
          </div>
          <div className="ltn__social-media-2">
            <ul>
              {social && social.map((item, i) => (
                <li key={i}>
                  <a href={item.url} target="_blank">
                    <i className={item.icon} />
                  </a>
                </li>
              ))}
              {/* <li>
                  <a href="#" title="Facebook">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="#" title="Twitter">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" title="Linkedin">
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#" title="Instagram">
                    <i className="fab fa-instagram" />
                  </a>
                </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarV5;
