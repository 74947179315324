import React, { Component } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../lib/axios";

const Kvkk = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [kvkk, setKvkk] = useState(null);

  useEffect(() => {
    axios(`/kvkk/frontend?code=${localStorage.getItem("lang")}`)
      .then((response) => {
        setKvkk(response.data.kvkk);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (!kvkk) {
    return null;
  } else {
    return <div dangerouslySetInnerHTML={{ __html: kvkk.content }}></div>;
  }
};

export default Kvkk;
