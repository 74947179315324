import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Kvkk from "../global-components/kvkk";

class WishList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kvkk: [],
      text: [],
    };
  }

  componentDidMount() {
    this.setState({
      kvkk: this.props.kvkk,
      text: this.props.text,
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    const { kvkk, text } = this.state;

    return (
      <>
        <div className="liton__wishlist-area mb-105">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    {kvkk.subtitle}
                  </h6>
                  <h3>{kvkk.title}</h3>
                </div>
              </div>
            </div>

            <div className="row kvk-content">
              <div
                className="col-lg-12"
                dangerouslySetInnerHTML={{ __html: kvkk.content }}
              ></div>
            </div>
          </div>
        </div>
        <div className="aydinlatma-metni mb-105">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    {text.subtitle}
                  </h6>
                  <h3>{text.title}</h3>
                </div>
              </div>
            </div>

            <div
              className="row kvk-content"
              dangerouslySetInnerHTML={{ __html: text.content }}
            ></div>
          </div>
        </div>
      </>
    );
  }
}

export default WishList;
