import React, { Component } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/social";
import Copyright from "./copyright";
import CookieBanner from "./cookies";

import axios, { API_URL } from "../../lib/axios";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { ArrayContext } from "../../Added/Context";

const Footer_v1 = () => {
  const {
    categoriesArray,
    footerPage,
    footerData,
    setFooterPage,
    setFooterSettings,
    setCategoriesArray,
    setFooterData,
  } = useContext(ArrayContext);

  const [page, setPage] = useState(footerPage);
  const [categories, setCategories] = useState(categoriesArray);
  const [data, setData] = useState(footerData);

  const [topProduct, setTopProduct] = useState(null);
  const [serviceSale, setServiceSale] = useState(null);

  if (localStorage.getItem("lang") === "tr") {
    var hakkimizdaMenu = [
      {
        label: "Kurumsal",
        items: [
          {
            label: "Hakkımızda",
            target: "hakkimizda",
            url: "/kurumsal",
          },
          {
            label: "Tarihçe",
            target: "tarihce",
            url: "/kurumsal",
          },
          {
            label: "AR-GE",
            target: "arge",
            url: "/kurumsal",
          },
          {
            label: "Sertifikalar",
            target: "sertifika",
            url: "/kurumsal",
          },
        ],
      },
    ];
    var satisservisMenu = [
      {
        label: "Satış & Servis",
        items: [
          {
            label: "Satış Noktaları",
            target: "satis",
            url: "/satis-servis",
          },
          {
            label: "Servis Merkezleri",
            target: "servis",
            url: "/satis-servis",
          },
          {
            label: "Yatırımcı İlişkileri",
            target: "yatirimci",
            url: "/yatirimci-iliskileri",
          },
          {
            label: "İletişim",
            target: "iletisim",
            url: "/iletisim",
          },
        ],
      },
    ];
  } else {
    var hakkimizdaMenu = [
      {
        label: "Corporate",
        items: [
          {
            label: "About Us",
            target: "hakkimizda",
            url: "/corporate",
          },
          {
            label: "Our History",
            target: "tarihce",
            url: "/corporate",
          },
          {
            label: "R&D",
            target: "arge",
            url: "/corporate",
          },
          {
            label: "Certificates",
            target: "sertifika",
            url: "/corporate",
          },
        ],
      },
    ];
    var satisservisMenu = [
      {
        label: "Sales & Service",
        items: [
          {
            label: "Selling Points",
            target: "satis",
            url: "/sale-service",
          },
          {
            label: "Service Points",
            target: "servis",
            url: "/sale-service",
          },
          {
            label: "Contact",
            target: "iletisim",
            url: "/contact",
          },
        ],
      },
    ];
  }

  useEffect(() => {
    Promise.all([
      axios(`/setting/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setFooterPage(data.data.settings);
          setPage(data.data.settings);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
      axios(`/footer/frontend?code=${localStorage.getItem("lang")}`).then(
        (response) => {
          if (response.data.footer) {
            setFooterData(response.data.footer);
            setData(response.data.footer);
          }
        }
      ),
      axios(
        `/topproductcompolent/frontend?code=${localStorage.getItem("lang")}`
      )
        .then((response) => {
          setTopProduct(response.data.topProductLang[0]);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
      axios(`/servicesales/frontend?code=${localStorage.getItem("lang")}`)
        .then((response) => {
          setServiceSale(response.data.serviceSalesPage);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
    ]).catch((error) => {
      console.log("Hata:", error);
    });
    if (!categories) {
      axios(`/category/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setCategoriesArray(data.data.category);
          setCategories(data.data.category);
        })
        .catch((error) => {
          console.log("Hata:", error);
        });
    }
  }, []);

  function componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }

  useEffect(() => {
    componentDidMount();
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "Footer logo";

  if (!page || !data) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-2 plr--5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      {page?.footerlogo && (
                        <img
                          src={
                            API_URL +
                            "/api/image?image=" +
                            page.footerlogo +
                            "&path=footerlogo"
                          }
                          alt="Logo"
                        />
                      )}
                    </div>
                  </div>
                  <div className="footer-address">
                    <ul>
                      {data.info &&
                        data.info.map((item) => (
                          <li key={item.key}>
                            <div className="footer-address-icon">
                              <i className={item.icon} />
                            </div>
                            <div className="footer-address-info">
                              <p>{item.info}</p>
                            </div>
                          </li>
                        ))}
                      {/* <li>
                        <div className="footer-address-icon">
                          <i className="icon-call" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="tel:+902328333764">+90 (232) 833 37 64</a>
                          </p>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                  <div className="ltn__social-media mt-20">
                    <Social />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">{hakkimizdaMenu[0].label}</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      {hakkimizdaMenu[0].items.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={{
                              pathname: item.url,
                              state: { target: item.target },
                            }}
                          >
                            {item.label}
                          </Link>
                        </li>
                      ))}

                      {/* <li>
                        <Link
                          to={{
                            pathname: "/kurumsal",
                            state: { target: "hakkimizda" },
                          }}
                        >
                          Hakkımızda
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname: "/kurumsal",
                            state: { target: "tarihce" },
                          }}
                        >
                          Tarihçe
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname: "/kurumsal",
                            state: { target: "arge" },
                          }}
                        >
                          AR-GE
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname: "/kurumsal",
                            state: { target: "sertifika" },
                          }}
                        >
                          Sertifikalar
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  {topProduct && (
                    <h4 className="footer-title">{topProduct.pageTitle}</h4>
                  )}
                  <div className="footer-menu go-top">
                    <ul>
                      {categories &&
                        categories.map((category, index) => (
                          <li key={index}>
                            <Link
                              onClick={() =>
                                window.scrollTo({ top: 0, behavior: "auto" })
                              }
                              to={{
                                pathname: localStorage.getItem("lang") === "tr" ? "/urunler" : "/products",
                                state: {
                                  tab: category.categorylang[0].sef,
                                },
                              }}
                            >
                              {category.categorylang[0].title}
                            </Link>
                          </li>
                        ))}
                      {/* <li>
                        <Link
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "auto" })
                          }
                          to={{ pathname: "/urunler", state: { tab: "resim" } }}
                        >
                          E-series
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  {serviceSale && (
                    <h4 className="footer-title">{serviceSale.title}</h4>
                  )}
                  <div className="footer-menu go-top">
                    <ul>
                      {satisservisMenu[0].items.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={{
                              pathname: item.url,
                              state: { target: item.target },
                            }}
                            onClick={() =>
                              window.scrollTo({ top: 0, behavior: "auto" })
                            }
                          >
                            {item.label}
                          </Link>
                        </li>
                      ))}

                      {/* <li>
                        <Link
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "auto" })
                          }
                          to={{
                            pathname: "/satis-servis",
                            state: { tab: "satis" },
                          }}
                        >
                          Satış Noktaları
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "auto" })
                          }
                          to={{
                            pathname: "/satis-servis",
                            state: { tab: "servis" },
                          }}
                        >
                          Servis Merkezleri
                        </Link>
                      </li>
                      {/*<li><Link to="/location">ServisNet</Link></li>*/}
                      {/* 
                      {localStorage.getItem("lang") == "tr" && (
                        <li>
                          <Link
                            onClick={() =>
                              window.scrollTo({ top: 0, behavior: "auto" })
                            }
                            to="/yatirimci-iliskileri"
                          >
                            Yatırımcı İlişkileri
                          </Link>
                        </li>
                      )}
                      <li>
                        <Link
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "auto" })
                          }
                          to="/iletisim"
                        >
                          İletişim
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Copyright />
        <CookieBanner />
        <div style={{
          backgroundColor: "#171b2a",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
        }}>
            <span style={{
              color: "white",
            }}>Developed by </span>
            <a
              href="https://www.reelyazilim.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#f25a24",
                fontWeight: "bold",
              }}
            >
              Reel Yazılım
            </a>
        </div>
      </footer>
    );
  }
};

export default Footer_v1;
