import React, { Component } from "react";
import axios, { API_URL } from "../../lib/axios";
import { useState } from "react";
import { useEffect } from "react";

// import lightbox2
import "lightbox2/dist/css/lightbox.min.css";
import lightbox from "lightbox2/dist/js/lightbox.min.js";

const Testimonial = (props) => {
  const [data, setData] = useState(props.data || null);

  const x = props.x || 0;

  //console.log("props", props.x);

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  useEffect(() => {
    const $ = window.$;
    if (props.data && x == 0) {
      // $("a[data-rel^=lightcase]").lightcase({
      //   transition:
      //     "elastic" /* none, fade, fadeInline, elastic, scrollTop, scrollRight, scrollBottom, scrollLeft, scrollHorizontal and scrollVertical */,
      //   swipe: true,
      //   maxWidth: 1500,
      //   maxHeight: 750,
      //   innerWidth: 900,
      //   innerHeight: 600,
      //   outerWidth: 900,
      //   outerHeight: 600,
      //   width: 900,
      //   height: 600,
      // });

      lightbox.option({
        resizeDuration: 200,
        wrapAround: true,
      });

      $(".ltn__testimonial-slider-5-active").slick({
        arrows: true,
        centerMode: false,
        centerPadding: "80px",
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow:
          '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
        nextArrow:
          '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              dots: true,
              centerMode: false,
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              dots: true,
              centerMode: false,
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 580,
            settings: {
              arrows: false,
              dots: true,
              centerMode: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    }
  }, [props.data] || [props.x]);

  if (!data) return null;

  return (
    <div
      id="i_sertifika"
      className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
      data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
    >
      {data && (
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color"></h6>
                <h1 className="section-title">
                  {localStorage.getItem("lang") === "en" ? "Certificates" : "Sertifikalarımız"}
                </h1>
              </div>
            </div>
          </div>
          {data && (
            <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
              {data.map((item) => {
                if (item.certificatelang)
                  return (
                    <div className="col-lg-4">
                      <div className="ltn__testimonial-item ltn__testimonial-item-7">
                        <div className="ltn__testimoni-info">
                          <a
                            href={
                              API_URL +
                              "/api/image?path=certificate&image=" +
                              item.image.replaceAll(" ", "%20")
                            }
                            data-lightbox="image-1"
                            data-title={item.certificatelang[0].title}
                          >
                            <img
                              src={
                                API_URL +
                                "/api/image?path=certificate&image=" +
                                item.image.replaceAll(" ", "%20")
                              }
                              alt="Image"
                              style={{ height: "280px" }}
                            />
                          </a>
                          {/*  */}

                          <div className="ltn__testimoni-info-inner">
                            <div className="ltn__testimoni-name-designation">
                              <h5>{item.certificatelang[0].title}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          )}
          {/* <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <a
                    href={publicUrl + "assets/img/team/ts22163.jpeg"}
                    data-rel="lightcase:myCollection"
                  >
                    <img
                      src={publicUrl + "assets/img/team/ts22163.jpeg"}
                      alt="Image"
                    />
                  </a>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-name-designation">
                      <h5>IRIS ISO/TS 22163</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </div>
      )}
    </div>
  );
};

export default Testimonial;
