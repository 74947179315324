import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../lib/axios";
import Select from "react-select";

const LanguageSelector = () => {
    const [langs, setLangs] = useState(null);
    const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lang"));

    useEffect(() => {
        axios.get(API_URL + "/api/language/frontend").then((response) => {
            setLangs(response.data.language);
            if(!selectedLang) {
                setSelectedLang("tr");
            }
        });
    }, []);


    const getFlagIcon = (code) => {
        switch (code) {
            case "tr":
                return "/assets/flag/turkey.png";
            case "en":
                return "/assets/flag/united-kingdom.png";
            default:
                return null;
        }
    };
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: "1px solid #e2e8f0",
            borderRadius: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            margin: "0",
            height: "50px",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0",
            height: "100%",
        }),
        input: (provided, state) => ({
            ...provided,
            margin: "0",
            height: "100%",
        }),
      };

    return (
        <div className="language-selector">
        <Select
            styles={customStyles}
            isSearchable={false}
            isClearable={false}
            options={langs ? langs.map((lang) => {
                return {
                value: lang.code,
                label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={getFlagIcon(lang.code)}
                        alt={lang.code}
                        style={{ width: "20px", height: "20px", marginRight: "10px" }}
                    />
                    {lang.name}
                    </div>
                ),
                };
            }) : []}
            onChange={(selectedOption) => {
                setSelectedLang(selectedOption.value);
                localStorage.setItem("lang", selectedOption.value);
                window.location.reload();
            }}
            placeholder={
                <div>
                <img
                    src={getFlagIcon(localStorage.getItem("lang"))}
                    alt={localStorage.getItem("lang")}
                    style={{ width: "20px", height: "20px", marginRight: "10px" }}
                />
                {langs ? langs.find((lang) => lang.code === localStorage.getItem("lang")).name : "Language"}
                </div>
            }
        />
        </div>
    );
};

export default LanguageSelector;
