import React, { useEffect, useState } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import PortfolioV1 from "./section-components/portfolio-v2";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import axios, { API_URL } from "../lib/axios";

const PortfolioV2 = () => {
  const [image, setImage] = useState(null);
  const [productLang, setProductLang] = useState({});

  useEffect(() => {
    axios(
      `/topproductcompolent/frontend?code=${localStorage.getItem("lang")}`
    ).then((res) => {
      if (res.data.success) {
        setImage(res.data.topProduct[0]);
        setProductLang(res.data.topProductLang[0]);
      }
    });
  }, []);

  return (
    <div>
      <Navbar />
      {image && productLang && (
        <PageHeader
          headertitle={productLang.title}
          Img={
            API_URL + "/api/image?image=" + image.bread + "&path=productbread"
          }
        />
      )}
      <PortfolioV1 />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default PortfolioV2;
