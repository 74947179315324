import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios, { API_URL, API_URL2 } from "../../lib/axios";

const QRPage = (props) => {
  const { id } = useParams();
  const [result, setResult] = useState(null);
  const [isUrl, setIsUrl] = useState(false);
  const [notfound, setNotfound] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlResponse = await axios.get(`${API_URL}/api/qr/url/${encodeURIComponent(id)}`);
        if (urlResponse.status === 200) {
          setResult(urlResponse.data);
          setIsUrl(true);
          return;
        }
      } catch (err) {
        // handle error if needed
      }

      try {
        const detailResponse = await axios.get(`${API_URL}/api/qr/detail/${encodeURIComponent(id)}`);
        if (detailResponse.status === 200) {
          setResult(detailResponse.data);
          setIsUrl(false);
          return;
        }
      } catch (err) {
        // handle error if needed
      }

      setNotfound(true);
    };

    fetchData();
  }, [id]);


  const resultByType = (result) => {
    if (result.active === false) {
      return (
        <h1 className="text-center mt-5">
          {localStorage.getItem("lang") !== "en" ? "QR Kod aktif değil" : "QR Code is not active"}
        </h1>
      );
    }
  
    if (result.type === "link") {
      window.location.href = result.value;
    }
  
    if (result.type === "image") {
      return <img src={`${API_URL}/api/image?image=${encodeURIComponent(result.value)}&path=qr`} alt="QR Code" />;
    }
  
    if (result.type === "file") {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
      if (isMobile) {
        const downloadPdf = async () => {
          try {
            const response = await fetch(`${API_URL2}/api/docs?docs=${encodeURIComponent(result.value)}&path=qr/pdf`);
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = localStorage.getItem("lang") !== "en" ? "Dosya.pdf" : "File.pdf";
            document.body.appendChild(link);
            link.click();
            link.remove();
          } catch (error) {
            console.error('Dosya indirilemedi:', error);
          }
        };
  
        return (
          <div className="text-center mt-5">
            <button onClick={downloadPdf} className="btn btn-primary">
              {localStorage.getItem("lang") !== "en" ? "PDF Görüntüle" : "View PDF"}
            </button>
          </div>
        );
      } else {
        return (
          <div style={{ width: "100%", height: "100vh", margin: "0", overflow: "hidden" }}>
            <object
              style={{ width: "100%", height: "100vh", overflow: "auto" }}
              data={`${API_URL2}/api/docs?docs=${encodeURIComponent(result.value)}&path=qr/pdf`}
              type="application/pdf"
            ></object>
          </div>
        );
      }
    }
  };
  
  

  
  
  

  return (
    <div>
      {result ? (
        <div>{resultByType(result)}</div>
      ) : notfound ? (
        <h1 className="text-center mt-5">
          {localStorage.getItem("lang") !== "en" ? "QR Kod bulunamadı" : "QR Code not found"}
        </h1>
      ) : (
        <h1 className="text-center mt-5">
          {localStorage.getItem("lang") !== "en" ? "Yükleniyor..." : "Loading..."}
        </h1>
      )}
    </div>
  );
};

export default QRPage;
