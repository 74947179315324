import React, { Component } from "react";
import { Link } from "react-router-dom";
import Comments from "./comments";
import moment from "moment";
import { API_URL } from "../../lib/axios";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './lightbox-custom.css'; // Import the custom CSS file

class BlogDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      photoIndex: 0,
    };
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    const { isOpen, photoIndex } = this.state;

    const { blog } = this.props;

    if (!blog || !blog.bloglang) return null;

    return (
      <div className="ltn__page-details-area ltn__blog-details-area mb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="ltn__blog-details-wrap">
                <div className="ltn__page-details-inner ltn__blog-details-inner">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-category">
                        <Link to="/haberler">Haber</Link>
                      </li>
                    </ul>
                  </div>
                  <h2 className="ltn__blog-title blog-detail-title">
                    {blog?.bloglang[0]?.title}
                  </h2>
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author go-top">
                        <img
                          src={publicUrl + "assets/img/favicon.png"}
                          alt="#"
                        />
                        Safkar
                      </li>
                      <li className="ltn__blog-date">
                        <i className="far fa-calendar-alt" />
                        {moment(blog.createdAt)
                          .locale(localStorage.getItem("lang"))
                          .format("MMMM Do YYYY")}
                      </li>
                    </ul>
                  </div>
                  <img
                    src={
                      API_URL +
                      "/api/image?image=" +
                      blog.image.replaceAll(" ", "%20") +
                      "&path=blog"
                    }
                    alt="Image"
                  />

                  <p
                    dangerouslySetInnerHTML={{
                      __html: blog.bloglang[0]?.content,
                    }}
                  ></p>
                  <hr />

                  <div className="row">
                    {blog?.bloggalleries.map((item, index) => (
                      <div className="col-lg-4 col-sm-4 col-6" key={item.id}>
                        <button
                          onClick={() => this.setState({ isOpen: true, photoIndex: index })}
                        >
                          <img
                            style={{ maxHeight: "150px", marginBottom: "15px" }}
                            src={
                              API_URL +
                              "/api/image?image=" +
                              item.image.replaceAll(" ", "%20") +
                              "&path=blog/gallery"
                            }
                            alt="Image"
                          />
                        </button>
                      </div>
                    ))}

                    {isOpen && (
                      <Lightbox
                        mainSrc={API_URL + "/api/image?image=" + blog.bloggalleries[photoIndex].image.replaceAll(" ", "%20") + "&path=blog/gallery"}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        nextSrc={API_URL + "/api/image?image=" + blog.bloggalleries[(photoIndex + 1) % blog.bloggalleries.length].image.replaceAll(" ", "%20") + "&path=blog/gallery"}
                        prevSrc={API_URL + "/api/image?image=" + blog.bloggalleries[(photoIndex + blog.bloggalleries.length - 1) % blog.bloggalleries.length].image.replaceAll(" ", "%20") + "&path=blog/gallery"}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + blog.bloggalleries.length - 1) % blog.bloggalleries.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % blog.bloggalleries.length,
                          })
                        }
                        enableZoom={true}
                        closeLabel="Kapat"
                        nextLabel="Sonraki"
                        prevLabel="Önceki"
                        
                      />
                    )}
                  </div>
                </div>
                {/* blog-tags */}
                <div className="ltn__blog-tags-social-media mt-80 row">
                  <div className="ltn__tagcloud-widget col-lg-12 go-top">
                    {blog?.bloglang[0]?.tags.length > 0 && <h4>Etiketler</h4>}
                    <ul>
                      {blog?.bloglang[0]?.tags.map((item, index) => (
                        <li key={index}>
                          <Link to="/haberler">{item}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogDetails;
