import React, { Component } from "react";
import axios from "../../lib/axios";
import { useEffect } from "react";
import { useState } from "react";

const Ekip = () => {
  const [arge, setArge] = useState([]);
  const [team, setTeam] = useState([]);
  const [teammembers, setTeammembers] = useState([]);

  function componentDidMount() {
    axios(`/arge/frontend?code=${localStorage.getItem("lang")}`)
      .then((data) => {
        setArge(data.data.arge);
      })
      .catch((error) => {
        console.log("Hata:", error);
      });
    axios(`/team/frontend?code=${localStorage.getItem("lang")}`)
      .then((data) => {
        setTeam(data.data.team);
        setTeammembers(data.data.teammember);
      })
      .catch((error) => {
        console.log("Hata:", error);
      });
  }

  useEffect(() => {
    componentDidMount();
  }, []);

  if (!arge[0]) return null;

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div id="i_arge" className="container mb-150">
      {arge[0] && (
        <>
          <div className="row mb-150">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h1 className="section-title">{arge[0].title}</h1>
              </div>
              <div className="about-us-info-wrap arge-wrap">
                <p>{arge[0].content}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h1 className="section-title">
                  {team.length > 0 ? team[0].title : "Yönetim Organizasyon"}
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="ltn__team-details-member-info-details">
              <div className="row organizasyon-div">
                <div className="col-lg-10">
                  <div className="ltn__team-details-member-about row">
                    {teammembers &&
                      teammembers.map((teammember, index) => (
                        <li
                          className={`col-lg-3`}
                          style={{ listStyle: "none", marginTop: "50px" }}
                          key={index}
                        >
                          <strong>{teammember.name}</strong>{" "}
                          <p>{teammember.teammemberlang[0].tag}</p>
                        </li>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Ekip;
