import React, { Component } from "react";
import axios, { API_URL } from "../../lib/axios";

class PopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true, // modalı açılır açılmaz göstermek için showModal durumunu true olarak ayarlayın
      popup: null,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    axios
      .get(`/popup/frontend?code=${localStorage.getItem("lang")}`)
      .then((response) => {
        if (response.data.popup.length > 0 && response.data.popup[0].image) {
          this.setState({ popup: response.data.popup[0] });
        }
      });
  }

  handleAxiosResponse(response) {
    this.setState({ popupImage: response.data.image });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    // modalı kapatmak için showModal durumunu false olarak ayarlayın
    // kapatma işlemi gerçekleştikten sonra bir cookie ayarla
    document.cookie =
      "popupClosed=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
  }
  render() {
    return (
      <>
        {this.state.popup &&
          this.state.showModal && ( // show modal durumu true olduğunda modalı göster
            <div className="popup-modal ltn__modal-area ltn__modal-area-duyuru">
              <div
                id="ltn_popup_modal"
                tabIndex={-1}
                onClick={this.handleCloseModal}
              >
                <div
                  className="modal-dialog modal-dialog-big modal-md"
                  role="document"
                >
                  <div
                    className="modal-content"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleCloseModal}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="ltn__quick-view-modal-inner">
                        <div className="modal-product-item">
                          <div className="row">
                            <div className="col-12">
                              <div className="modal-product-info">
                                <h1
                                  style={{
                                    fontSize: "24px",
                                  }}
                                >
                                  {this.state.popup.title}
                                </h1>
                                {this.state.popup.link ? (
                                  <a
                                    href={this.state.popup.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={
                                        API_URL +
                                        "/api/image?image=" +
                                        this.state.popup.image +
                                        "&path=popup"
                                      }
                                      alt="duyuru"
                                    />
                                  </a>
                                ) : (
                                  <img
                                    src={
                                      API_URL +
                                      "/api/image?image=" +
                                      this.state.popup.image +
                                      "&path=popup"
                                    }
                                    alt="duyuru"
                                  />
                                )}
                                <div
                                  style={{
                                    marginTop: "20px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.popup.content,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}

export default PopUp;
