import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV2 from "./components/home-v2";
import About from "./components/about";
import PortfolioV2 from "./components/portfolio-v2";
import Faq from "./components/faq";
import ComingSoon from "./components/coming-soon";
import Error from "./components/404";
import Location from "./components/location";
import Shop from "./components/shop";
import Penguen485 from "./components/penguen485";
import BlogGrid from "./components/blog-grid";
import BlogDetails from "./components/blog-details";
import Contact from "./components/contact";
import MyAccount from "./components/my-account";
import KvkkAydinlatma from "./components/wishlist";
import { Redirect } from "react-router-dom";
import "moment/locale/tr";
import "moment/moment";
import "./index.css";
import { ArrayProvider } from "./Added/Context";
import CreatePDF from "./components/shop-components/pdf";
import PDFComponent from "./components/shop-components/pdf2";
import QRPage from "./components/shop-components/qrPage";
import PDFPage2 from "./components/shop-components/pdfpage2";
import axios, { API_URL } from "./lib/axios";
import PDFPage from "./components/shop-components/pdfpage";

const Root = () => {
  if (!localStorage.getItem("lang")) {
    localStorage.setItem("lang", "tr");
  }

  const [languageActive, setLanguageActive] = useState(null);

  useEffect(() => {
    axios(`/languageActive/frontend`)
    .then((data) => {
      setLanguageActive(data.data.active);
      if (data.data.active != true) {
        localStorage.setItem("lang", "tr");
      }
    })
  }, []);

  const RedirectToExternalLink = () => {
    window.location.href = "http://panel.safkar.com/";
    return <Redirect to="/" />;
  };

  return (
    <Router basename="/">
      <ArrayProvider>
        <Switch>
          <Route exact path="/" component={HomeV2} />
          <Route path="/kurumsal" component={About} />
          <Route path="/gizlilik" component={KvkkAydinlatma} />
          <Route path="/urunler" component={PortfolioV2} />
          <Route path="/faq" component={Faq} />
          <Route path="/coming-soon" component={ComingSoon} />
          <Route path="/404" component={Error} />
          <Route path="/satis-servis" component={Location} />
          {/* <Route path="/shop" component={Shop} /> */}
          <Route path="/urun-detay/:id" component={Penguen485} />
          <Route path="/haberler" component={BlogGrid} />
          <Route path="/haber/:id" component={BlogDetails} />
          <Route path="/iletisim" component={Contact} />
          <Route path="/yatirimci-iliskileri" component={MyAccount} />
          <Route path="/panel" component={RedirectToExternalLink} />
          <Route path="/pdf" component={CreatePDF} />
          <Route path="/pdf2" component={PDFComponent} />
          <Route path="/dosya/:id" component={PDFPage2} /> 
          <Route path="/belge/:id" component={PDFPage} />
          <Route path="/qr/:id" component={QRPage} />
          {/* <Route path="/giris" component={Login} /> */}
          {/* <Route path="/kayit" component={Register} /> */}
          {/* english routes */}
          {languageActive == true &&
          <>
          <Route path="/corporate" component={About} />
          <Route path="/privacy" component={KvkkAydinlatma} />
          <Route path="/products" component={PortfolioV2} />
          <Route path="/sale-service" component={Location} />
          <Route path="/product-detail/:id" component={Penguen485} />
          <Route path="/news" component={BlogGrid} />
          <Route path="/news-detail/:id" component={BlogDetails} />
          <Route path="/contact" component={Contact} />
          <Route path="/investor-relations" component={MyAccount} />
          <Route path="/file/:id" component={PDFPage2} />
          <Route path="/document/:id" component={PDFPage} />
          </>
          }
          <Route component={Error} />
        </Switch>
      </ArrayProvider>
    </Router>
  );
};

export default Root;

ReactDOM.render(<Root />, document.getElementById("quarter"));
