import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import AboutV4 from "./section-components/about-v4";
import CounterV1 from "./section-components/counter-v1";
import Ekip from "./section-components/ekip";
import HistoryV1 from "./section-components/history";
import Testimonial from "./section-components/testimonial-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import axios, { API_URL } from "../lib/axios";

const About_v1 = () => {
  const [page, setPage] = useState(null);
  const [data, setData] = useState(null);
  const [certificate, setCertificate] = useState(null);

  const [isPageRedirected, setIsPageRedirected] = useState(false);

  const { state } = useLocation();

  useEffect(() => {
    Promise.all([
      axios
        .get(`/about/frontend?code=${localStorage.getItem("lang")}`)
        .then((response) => {
          if (response.data.success) {
            setData(response.data.aboutlang);
            setPage(response.data.about);

            if (state && state.target) {
              scrollToElement(state.target);
              window.history.replaceState({}, document.title);
            }
          }
        }),

      axios(`/certificate/frontend?code=${localStorage.getItem("lang")}`)
        .then((data) => {
          setCertificate(data.data.certificate);
          setIsPageRedirected(true);
        })
        .catch((error) => {
          console.log("Hata:", error);
        }),
    ]);
  }, [state]);

  // useEffect(() => {
  //   if (state && state.target) {
  //     console.log(state.target)
  //     scrollToElement(state.target);
  //     window.history.replaceState({}, document.title);
  //   }
  // }, [state][page]);

  const scrollToElement = (selected) => {
    if (selected == "hakkimizda") {
      var el = document.getElementById("i_hakkimizda");
      window.scroll(gettOffset(el, 0));
    } else if (selected == "tarihce") {
      var el = document.getElementById("i_tarihce");
      window.scroll(gettOffset(el, -200));
    } else if (selected == "arge") {
      var el = document.getElementById("i_arge");
      window.scroll(gettOffset(el, -150));
    } else if (selected == "sertifika") {
      var el = document.getElementById("i_sertifika");
      if (isPageRedirected) {
        window.scroll(gettOffset(el, 0));
      } else {
        window.scroll(gettOffset(el, -180));
      }
    }
    window.history.replaceState({}, document.title);
  };

  const gettOffset = (el, marginY) => {
    if (!el) {
      setTimeout(() => {
        scrollToElement(state.target);
      }, 100);
      return {
        left: 0,
        top: 0,
      };
    }
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY + marginY,
    };
  };

  if (page || data || certificate) {
    return (
      <div>
        <Navbar />
        {page && data && (
          <PageHeader
            headertitle={data.title}
            Img={
              API_URL +
              "/api/image?image=" +
              page.breadcrumb +
              "&path=breadcrumb"
            }
          />
        )}
        {data && page && <AboutV4 data={data} image={page.image} />}
        {/* <CounterV1 /> */}
        <HistoryV1 />
        {certificate && (
          <Testimonial data={certificate} x={isPageRedirected ? 1 : 0} />
        )}
        <Ekip />
        <CallToActionV1 />
        <Footer />
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 750,
        }}
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
};

export default About_v1;
