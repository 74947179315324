import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import axios, { API_URL } from "../../lib/axios";
import { useEffect } from "react";
import { useState } from "react";
import { ArrayContext } from "../../Added/Context";
import { useContext } from "react";

const ServiceV5 = (props) => {
  const { categoriesArray } = useContext(ArrayContext);

  const [categories, setCategories] = useState(categoriesArray);
  const [categorryLinks, setCategorryLinks] = useState([
    {
      tabs: "ticari",
    },
    {
      tabs: "frigorifik",
    },
    {
      tabs: "raylı",
    },
    {
      tabs: "savunma",
    },
    {
      tabs: "resim",
    },
  ]);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="ltn__service-area pt-115 pb-70 go-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              {props.title && <h1 className="section-title">{props.title}</h1>}
            </div>
          </div>
        </div>
        <div className="row  justify-content-center">
          {categories?.map((category, index) => (
            <div className="col-lg-4 col-sm-6 col-12" key={index}>
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={
                      API_URL +
                      "/api/image?image=" +
                      category.image +
                      "&path=category"
                    }
                    alt="#"
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link
                      to={{
                        pathname: "/urunler",
                        state: {
                          tab: category.categorylang[0].sef,
                        },
                      }}
                    >
                      {category.categorylang[0].title}
                    </Link>
                  </h3>
                  <p>{category.categorylang[0].content}</p>
                </div>
              </div>
            </div>
          ))}

          {/* <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={publicUrl + "assets/img/icons/icon-ticari.png"}
                    alt="#"
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link
                      to={{ pathname: "/urunler", state: { tab: "ticari" } }}
                    >
                      Ticari Araç Klimaları
                    </Link>
                  </h3>
                  <p>
                    over 1 million+ homes for sale available on the website, we
                    can match you with a house you will want to call home.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={publicUrl + "assets/img/icons/icon-frigorifik.png"}
                    alt="#"
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link
                      to={{
                        pathname: "/urunler",
                        state: { tab: "frigorifik" },
                      }}
                    >
                      Frigorifik Soğutucular
                    </Link>
                  </h3>
                  <p>
                    over 1 million+ homes for sale available on the website, we
                    can match you with a house you will want to call home.
                  </p>
                </div>
              </div>
            </div>


            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={publicUrl + "assets/img/icons/icon-rayli.png"}
                    alt="#"
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link
                      to={{ pathname: "/urunler", state: { tab: "raylı" } }}
                    >
                      Raylı Sistemler
                    </Link>
                  </h3>
                  <p>
                    over 1 million+ homes for sale available on the website, we
                    can match you with a house you will want to call home.
                  </p>
                </div>
              </div>
            </div> 

            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={publicUrl + "assets/img/icons/icon-savunma.png"}
                    alt="#"
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link
                      to={{ pathname: "/urunler", state: { tab: "savunma" } }}
                    >
                      Savunma Sanayi
                    </Link>
                  </h3>
                  <p>
                    over 1 million+ homes for sale available on the website, we
                    can match you with a house you will want to call home.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={publicUrl + "assets/img/icons/icon-e-series2.png"}
                    alt="#"
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link
                      to={{ pathname: "/urunler", state: { tab: "resim" } }}
                    >
                      E-Series
                    </Link>
                  </h3>
                  <p>
                    over 1 million+ homes for sale available on the website, we
                    can match you with a house you will want to call home.
                  </p>
                </div>
              </div>
            </div>*/}
        </div>
      </div>
    </div>
  );
};

export default ServiceV5;
