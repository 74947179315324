import React, { useEffect } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const PDFComponent = () => {
  // PDF çıktısını almak istediğiniz bölümü bir referans olarak alın
  const printableRef = React.useRef();

  // PDF çıktısı alma işlemini gerçekleştiren fonksiyon
  const printPDF = () => {
    const input = printableRef.current;

    if (!input) {
      return;
    }

    // html2canvas kullanarak HTML içeriğini resme dönüştürün
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png'); // Resmi veri URL'ine dönüştürün
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        // Oluşturulan resmi PDF'e ekleyin
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('output.pdf'); // PDF'i indirin
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
      });
  };

  useEffect(() => {
    // Sayfa yüklendikten sonra PDF çıktısını almak için printPDF fonksiyonunu çağırın
    printPDF();
  }, []);

  return (
    <div>
      {/* PDF olarak çıktı almak istediğiniz bölümü referans olarak alın */}
      <div ref={printableRef}>
        {/* Buraya PDF olarak çıktı almak istediğiniz içeriği ekleyin */}
        <h1>PDF Çıktı Almak İstediğiniz İçerik</h1>
        <p>PDF olarak çıktı almak istediğiniz içeriği buraya ekleyin.</p>
      </div>
    </div>
  );
};

export default PDFComponent;
