import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Kvkk from "../global-components/kvkk";
import axios from "../../lib/axios";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    };
  }

  componentDidMount() {
    const checkbox = document.querySelector('input[name="agree"]');
    const button = document.querySelector('button[type="submit"]');

    checkbox.addEventListener("change", function () {
      if (this.checked) {
        button.removeAttribute("disabled");
      } else {
        button.setAttribute("disabled", "");
      }
    });

    const $ = window.$;

    // Get the form.
    var form = $("#contact-form");

    // Get the messages div.
    var formMessages = $(".form-messege");

    // Set up an event listener for the contact form.
    $(form).submit(function (e) {
      // Stop the browser from submitting the form.
      e.preventDefault();

      // Serialize the form data.
      var formData = $(form).serialize();


      // Submit the form using AJAX.
      $.ajax({
        type: "POST",
        url: $(form).attr("action"),
        data: formData,
      })
        .done(function (response) {
          // Make sure that the formMessages div has the 'success' class.
          $(formMessages).removeClass("error");
          $(formMessages).addClass("success");

          // Set the message text.
          $(formMessages).text(response);

          // Clear the form.
          $("#contact-form input,#contact-form textarea").val("");
        })
        .fail(function (data) {
          // Make sure that the formMessages div has the 'error' class.
          $(formMessages).removeClass("success");
          $(formMessages).addClass("error");

          // Set the message text.
          if (data.responseText !== "") {
            $(formMessages).text(data.responseText);
          } else {
            $(formMessages).text(
              "Oops! An error occured and your message could not be sent."
            );
          }
        });
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let select;

    const { form } = this.props;

    const getSelect = (select) => {
      return (
        <div className="col-md-6">
          <div className="input-item select-item-subject ltn__custom-icon">
            <select
              name="subject"
              className="custom-select"
              onChange={(e) => {
                this.setState({ subject: e.target.value });
              }}
            >
              <option disabled selected className="default-option">
                {
                  localStorage.getItem("lang") === "en" ? "Select Subject" : "Konu Seçiniz"
                }
              </option>
              {JSON.parse(select)["label"].map((item, i) => (
                <option key={i} value={item} className="custom-options">
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
      );
    };

    return (
      <div className="ltn__contact-message-area mb-120 mb--200">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__form-box contact-form-box box-shadow white-bg">
                <h4 className="title-2">
                  {
                    localStorage.getItem("lang") === "en" ? "Contact Form" : "İletişim Formu"
                  }
                </h4>
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();

                    const arr = {
                      name: this.state.name,
                      email: this.state.email,
                      subject: this.state.subject,
                      phone: this.state.phone,
                      message: this.state.message,
                    };

                    await toast.promise( axios.post("/smtp/mail", arr), {
                      pending: "Gönderiliyor...",
                      success: "Mesajınız başarıyla gönderildi.",
                      error: "Mesajınız gönderilirken bir hata oluştu.",
                      });
                  }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-item input-item-name ltn__custom-icon">
                        <input
                          type="text"
                          name="name"
                          placeholder={form && form.name}
                          onChange={(e) => {
                            this.setState({ name: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-item input-item-email ltn__custom-icon">
                        <input
                          type="email"
                          name="email"
                          placeholder={form && form.email}
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                        />
                      </div>
                    </div>

                    {form.select && getSelect(form.select)}
                    <div className="col-md-6">
                      <div className="input-item input-item-phone ltn__custom-icon">
                        <input
                          type="text"
                          name="phone"
                          placeholder={form && form.phone}
                          onChange={(e) => {
                            this.setState({ phone: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-item input-item-textarea ltn__custom-icon">
                    <textarea
                      name="message"
                      placeholder={form && form.province}
                      defaultValue={""}
                      onChange={(e) => {
                        this.setState({ message: e.target.value });
                      }}
                    />
                  </div>
                  <p>
                    <label className="input-info-save mb-0">
                      <input type="checkbox" name="agree" id="agree-checkbox" />{" "}
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#ltn_kvkk_modal"
                        target="_blank"
                      >
                      {localStorage.getItem("lang") === "en" ? "I read and agree the Personal Data Protection Law." : " KVKK metnini okudum ve onaylıyorum."}
                      </span>
                    </label>
                  </p>
                  <div className="btn-wrapper mt-0">
                    <button
                      className="btn theme-btn-1 btn-effect-1"
                      type="submit"
                      id="submit-button"
                      disabled
                    >
                      {form && form.button}
                    </button>
                  </div>
                  <p className="form-messege mb-0 mt-20" />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="kvkk-modal ltn__modal-area ltn__add-to-cart-modal-area----">
          <div className="modal fade" id="ltn_kvkk_modal" tabIndex={-1}>
            <div className="modal-dialog modal-md" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="ltn__quick-view-modal-inner">
                    <div className="modal-product-item">
                      <div className="row">
                        <div className="col-12">
                          <div className="modal-product-info">
                            <Kvkk />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
